import { useTheme } from '@mui/material/styles'
import { Radar } from 'react-chartjs-2'
import Card from '@mui/material/Card'
import CardActions from '@mui/material/CardActions'
import CardContent from '@mui/material/CardContent'
import Divider from '@mui/material/Divider'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import { PublicRestaurantReview } from '../types'
import moment from 'moment'
import EditIcon from '@mui/icons-material/Edit'
import DeleteIcon from '@mui/icons-material/Delete'
import { useContext, useEffect, useState } from 'react'
import useApiClient from '@/hook/ApiClient'
import usePrevious from 'react-use/lib/usePrevious'
import SnackbarAlertContext from '@/context/SnackbarAlertContext'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions
} from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { checkPermissions } from '@/helper'
import AuthContext from '@/context/AuthContext'

export type Props = {
  review: PublicRestaurantReview
  onDelete?: (id: string) => void
}

export default function ReviewPreviewCard({ review, onDelete }: Props) {
  const theme = useTheme()
  const navigate = useNavigate()
  const { user } = useContext(AuthContext)

  // Deletion dialog
  const [deletionDialogOpen, setDeletionDialogOpen] = useState(false)
  const handleClickDeletionDialogOpen = () => {
    setDeletionDialogOpen(true)
  }
  const handleDeletionDialogClose = () => {
    setDeletionDialogOpen(false)
  }

  const { healthy, aesthetics, environment, specialty, cooking, inspirit } =
    review.ratings

  const data = {
    labels: ['健康', '美學', '獨特', '念力', '細緻', '環境'],
    datasets: [
      {
        data: [healthy, aesthetics, specialty, inspirit, cooking, environment],
        backgroundColor: 'rgba(100, 150, 232, 0.2)',
        borderColor: theme.palette.secondary.main,
        borderWidth: 1
      }
    ]
  }

  const options = {
    layout: {
      autoPadding: false,
      padding: {
        bottom: 10
      }
    },
    scales: {
      r: {
        suggestedMin: 0,
        suggestedMax: 3,
        ticks: {
          display: false,
          stepSize: 1
        }
      }
    },
    plugins: {
      legend: {
        display: false
      }
    },
    elements: {
      line: {
        borderWidth: 1
      }
    }
  }

  const [
    { loading: deletingReview, error: reviewDeletingError },
    deleteReview,
    cancelDeletingReview
  ] = useApiClient<undefined>()

  const previousStateOfDeletingReview = usePrevious(deletingReview)

  const { showSnackbarAlert } = useContext(SnackbarAlertContext)

  useEffect(() => {
    if (previousStateOfDeletingReview && !deletingReview) {
      if (reviewDeletingError) {
        showSnackbarAlert({
          content: '評鑑刪除失敗',
          severity: 'error'
        })
      } else {
        showSnackbarAlert({
          content: '評論刪除成功',
          severity: 'success'
        })
        onDelete?.(review.id)
      }
    }
  }, [deletingReview, reviewDeletingError])

  const Content = (
    <CardContent>
      <Typography color="primary" variant="h5" component="div">
        {review.restaurant.name}
      </Typography>
      <Typography sx={{ fontSize: 10, mb: 1.5 }} color="text.secondary">
        {moment(review.visitedAt).format('YYYY.MM.DD')}
      </Typography>
      <Radar data={data} options={options} />
      <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
        總分：
        {healthy + aesthetics + environment + specialty + cooking + inspirit}
      </Typography>

      <Typography sx={{ fontSize: 12 }} color="text.secondary">
        {review.comments.substring(0, 40)}...
      </Typography>
    </CardContent>
  )

  const Actions = (
    <CardActions>
      <Button
        size="small"
        startIcon={<EditIcon />}
        disabled={!checkPermissions(['restaurant-review:update:self'], user)}
        onClick={() => {
          navigate('/restaurant-review-form/' + review.id)
        }}>
        編輯
      </Button>
      <Button
        size="small"
        startIcon={<DeleteIcon />}
        onClick={() => {
          handleClickDeletionDialogOpen()
        }}>
        刪除
      </Button>
      <Dialog open={deletionDialogOpen} onClose={handleDeletionDialogClose}>
        <DialogTitle>{'是否刪除餐飲評鑑?'}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            此評鑑的星星將回收給您，若您的星星數庫存不足或是已超過允許修改的期限，可能導致刪除失敗。此動作無法復原，請確認是否要刪除這筆餐飲評鑑。
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDeletionDialogClose} autoFocus>
            取消
          </Button>
          <Button
            onClick={() => {
              cancelDeletingReview()
              deleteReview({
                method: 'DELETE',
                url: `/restaurant-review/reviews/${review.id}`
              })

              handleDeletionDialogClose()
            }}>
            刪除
          </Button>
        </DialogActions>
      </Dialog>
    </CardActions>
  )

  return (
    <Card
      key={review.id}
      variant="outlined"
      sx={{ width: '250px', margin: '20px' }}>
      {Content}
      <Divider />
      {Actions}
    </Card>
  )
}

import { Box, Typography } from '@mui/material'

export default function FAQ() {
  return (
    <Box style={{ margin: 30, textAlign: 'left' }}>
      <Typography variant="h5">FAQ</Typography>
      <Typography variant="subtitle1">
        1. 如何刪除 ProjectMars 手機 app 帳號？
      </Typography>
      <Typography variant="subtitle1">
        進入手機 App 帳號頁面，點選「刪除帳號」即可。
      </Typography>
    </Box>
  )
}

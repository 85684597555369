import useApiClient from '@/hook/ApiClient'
import moment from 'moment-timezone'
import { useCallback, useEffect, useState } from 'react'
import * as XLSX from 'xlsx'
import {
  RestaurantReviewReportDetailsRow,
  RestaurantReviewReportRecord,
  RestaurantReviewReportSummaryRow
} from '../types'

const getMaxWidth = (
  rows: Record<string, string | number>[],
  header: string
): number => {
  const getWidth = (str: string) => {
    // eslint-disable-next-line no-control-regex
    const m = str.replace(/[\u0000-\u00ff]/g, '') // half-width characters
    return (str.length + m.length) * 1.2 // non-ascii char should be counted as 2, and multiply by 1.2 to make it look better
  }

  const maxWidth = 60
  const headerWidth = getWidth(header)
  const dataWidth = rows.reduce((max, row) => {
    const str: string = (row[header] ?? '').toString()
    const width = getWidth(str)
    return width > max ? width : max
  }, 0)
  return Math.min(maxWidth, Math.max(headerWidth, dataWidth))
}

const formatTime = (date: string) =>
  moment(date).tz('UTC').format('YYYY/MM/DD HH:mm:ss')

const removeTrailingZeros = (number: string) => {
  const [integer, decimal] = number.split('.')
  const decimalWithoutTrailingZeros = decimal.replace(/0+$/, '')
  return decimalWithoutTrailingZeros
    ? `${integer}.${decimalWithoutTrailingZeros}`
    : integer
}

const toRestaurantReviewReportDetilsRow = (
  review: RestaurantReviewReportRecord
) => ({
  '評鑑者姓名 (ID)': review.createdBy.name + ' (' + review.createdBy.id + ')',
  '評鑑 ID': review.id,
  '餐廳 ID': review.restaurant.id,
  餐廳名稱: review.restaurant.name,
  國家: review.restaurant.country.name,
  餐廳統編: review.restaurant.businessAdministrationNumber,
  交易使用幣別: review.currency,
  發票字軌: review.invoiceNumber,
  '交易金額 (原始幣別)': removeTrailingZeros(review.totalPrice),
  匯率: removeTrailingZeros(review.exchangeRate),
  '交易金額 (新台幣)': review.totalPriceInNtd,
  '補助金額 (新台幣)': review.reimbursedAmountInNtd,
  '補助撥款時間 (UTC)': review.reimbursedAt
    ? formatTime(review.reimbursedAt)
    : '尚未撥款',
  '用餐時間 (UTC)': formatTime(review.visitedAt),
  用餐人數: review.peopleAmount,
  健康: review.ratings.healthy,
  美學: review.ratings.aesthetics,
  環境: review.ratings.environment,
  獨特: review.ratings.specialty,
  細緻: review.ratings.cooking,
  念力: review.ratings.inspirit,
  用餐心得: review.comments,
  '評鑑建立時間 (UTC)': formatTime(review.createdAt),
  '評鑑更新時間 (UTC)': formatTime(review.updatedAt)
})

// define the order
const detailsWorksheetHeaders: readonly (keyof RestaurantReviewReportDetailsRow)[] =
  [
    '評鑑者姓名 (ID)',
    '餐廳名稱',
    '用餐時間 (UTC)',
    '交易使用幣別',
    '交易金額 (原始幣別)',
    '匯率',
    '交易金額 (新台幣)',
    '補助金額 (新台幣)',
    '發票字軌',
    '補助撥款時間 (UTC)',
    '用餐人數',
    '餐廳統編',
    '國家',
    '健康',
    '美學',
    '環境',
    '獨特',
    '細緻',
    '念力',
    '用餐心得',
    '評鑑建立時間 (UTC)',
    '評鑑更新時間 (UTC)',
    '評鑑 ID',
    '餐廳 ID'
  ] as const

const createDetailsWorksheet = (reviews: RestaurantReviewReportRecord[]) => {
  const rows: RestaurantReviewReportDetailsRow[] = reviews.map(
    toRestaurantReviewReportDetilsRow
  )

  const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(rows, {
    header: detailsWorksheetHeaders.slice()
  })

  worksheet['!cols'] = detailsWorksheetHeaders.map(header => {
    return {
      wch: getMaxWidth(rows, header)
    }
  })

  return worksheet
}

const summaryWorksheetHeaders: readonly (keyof RestaurantReviewReportSummaryRow)[] =
  [
    '姓名',
    '國家',
    '交易使用幣別',
    '交易金額 (新台幣)',
    '補助金額 (新台幣)',
    '用餐平均人數',
    '補助是否已全部撥款',
    '總評鑑數',
    '評鑑者 ID'
  ] as const

const toRestaurantReviewReportSummaryRow = ([userId, userReviews]: [
  string,
  RestaurantReviewReportRecord[]
]) => {
  const firstReview = userReviews[0]
  const totalAmount = userReviews.reduce(
    (acc, review) => acc + parseInt(review.totalPriceInNtd),
    0
  )
  const totalReimbursedAmount = userReviews.reduce(
    (acc, review) => acc + parseInt(review.reimbursedAmountInNtd),
    0
  )

  // create country string, seperate by colon ',' if there are more than one country
  const countries = userReviews
    .reduce((acc, review) => {
      const country = review.restaurant.country.name
      if (!acc.includes(country)) acc.push(country)
      return acc
    }, [] as string[])
    .join(', ')

  const currencies = userReviews
    .reduce((acc, review) => {
      const currency = review.currency
      if (!acc.includes(currency)) acc.push(currency)
      return acc
    }, [] as string[])
    .join(', ')

  return {
    '評鑑者 ID': userId,
    姓名: firstReview.createdBy.name,
    國家: countries,
    交易使用幣別: currencies,
    '交易金額 (新台幣)': totalAmount,
    '補助金額 (新台幣)': totalReimbursedAmount,
    補助是否已全部撥款: userReviews.every(review => review.reimbursedAt)
      ? '是'
      : '否',
    用餐平均人數:
      userReviews.reduce((a, b) => a + b.peopleAmount, 0) / userReviews.length,
    總評鑑數: userReviews.length
  }
}

const createSummaryWorksheet = (reviews: RestaurantReviewReportRecord[]) => {
  // group by user
  const userReviewsMap = reviews.reduce((acc, review) => {
    const user = review.createdBy
    const userReviews = acc.get(user.id) ?? []
    userReviews.push(review)
    acc.set(user.id, userReviews)
    return acc
  }, new Map<string, RestaurantReviewReportRecord[]>())

  const summaryRows: RestaurantReviewReportSummaryRow[] = Array.from(
    userReviewsMap.entries()
  ).map(toRestaurantReviewReportSummaryRow)

  const summaryWorksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(
    summaryRows,
    {
      header: summaryWorksheetHeaders.slice()
    }
  )

  summaryWorksheet['!cols'] = summaryWorksheetHeaders.map(header => {
    return {
      wch: getMaxWidth(summaryRows, header)
    }
  })

  return summaryWorksheet
}

const createExcelFileBuffer = (reviews: RestaurantReviewReportRecord[]) => {
  const summaryWorksheet = createSummaryWorksheet(reviews)
  const detailsWorksheet = createDetailsWorksheet(reviews)

  const workbook = XLSX.utils.book_new()
  XLSX.utils.book_append_sheet(workbook, summaryWorksheet, 'summary')
  XLSX.utils.book_append_sheet(workbook, detailsWorksheet, 'details')

  return XLSX.write(workbook, {
    type: 'buffer',
    bookType: 'xlsx'
  })
}

// period is in format YYYY-MM
export default function useRestaurantReviewReport(period: string): {
  getReport: () => void
  report: RestaurantReviewReportRecord[] | null
  excelReportFileBuffer: Buffer | null
  retrievingReport: boolean
  reportRetrievingError: Error | null
} {
  const [excelFileBuffer, setExcelFileBuffer] = useState<Buffer | null>(null)

  const [
    {
      data: reportRetrievingResponse,
      loading: retrievingReport,
      error: reportRetrievingError
    },
    getReportFromApi
  ] = useApiClient<{ report: RestaurantReviewReportRecord[] }>(
    '/restaurant-review/report'
  )

  useEffect(() => {
    if (reportRetrievingError || !reportRetrievingResponse || retrievingReport)
      return

    const buf = createExcelFileBuffer(reportRetrievingResponse.report)

    setExcelFileBuffer(buf)
  }, [reportRetrievingResponse, retrievingReport, reportRetrievingError])

  const getReport = useCallback(() => {
    getReportFromApi({
      method: 'get',
      params: {
        period
      }
    })
  }, [getReportFromApi, period])

  return {
    getReport,
    report: reportRetrievingResponse?.report ?? null,
    excelReportFileBuffer: excelFileBuffer,
    retrievingReport,
    reportRetrievingError
  }
}

import { useContext } from 'react'
import { Box, Button } from '@mui/material'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import Select, { SelectChangeEvent } from '@mui/material/Select'
import RestaurantFormContext from '@/restaurant/context/RestaurantFormContext'
import { countries } from '../types'
import { checkPermissions } from '@/helper'
import AuthContext from '@/context/AuthContext'

export type Props = {
  country: string
  onChangeCountry: (country: string) => void
}

const enable = false

export default function RestaurantControl({ country, onChangeCountry }: Props) {
  const { user } = useContext(AuthContext)
  const { openForm } = useContext(RestaurantFormContext)
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: '10%',
        gap: '10px'
      }}>
      <FormControl fullWidth>
        <InputLabel id="restaurant-form-country-select-label ">
          選擇國家
        </InputLabel>
        <Select
          labelId="restaurant-form-country-select-label"
          id="restaurant-form-country-select"
          value={country}
          label="選擇國家"
          onChange={(e: SelectChangeEvent) => onChangeCountry(e.target.value)}>
          {countries.map(c => (
            <MenuItem key={c.code} value={c.code}>
              {c.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      {enable && checkPermissions(['restaurant:create:'], user) && (
        <Button variant="contained" onClick={() => openForm('CREATE')}>
          新增餐廳 +
        </Button>
      )}
    </Box>
  )
}

import { useContext } from 'react'
import ListItem from '@mui/material/ListItem'
import ListItemText from '@mui/material/ListItemText'
import Grid from '@mui/material/Grid'
import { Button } from '@mui/material'
import { Restaurant } from '../types'
import RestaurantFormContext from '@/restaurant/context/RestaurantFormContext'
import { checkPermissions } from '@/helper'
import AuthContext from '@/context/AuthContext'

export type Props = {
  restaurant: Restaurant
}

const enable = false

export default function RestaurantListItem({ restaurant }: Props) {
  const { user } = useContext(AuthContext)
  const { openForm } = useContext(RestaurantFormContext)
  return (
    <>
      <Grid item xs={12} md={6}>
        <ListItem>
          <ListItemText
            primary={restaurant.name}
            secondary={
              restaurant.city + ', ' + restaurant.country.name
            }></ListItemText>
          {enable && checkPermissions(['restaurant:update:'], user) && (
            <Button
              variant="contained"
              onClick={() => openForm('EDIT', restaurant)}>
              修改
            </Button>
          )}
        </ListItem>
      </Grid>
    </>
  )
}

import AuthContext from '@/context/AuthContext'
import { User } from '@/context/type'
import Button from '@mui/material/Button'
import Link from '@mui/material/Link'
import { useContext, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { FormContainer, TextFieldElement } from 'react-hook-form-mui'
import Box from '@mui/material/Box'
import { Typography } from '@mui/material'
import useAxios from 'axios-hooks'
import config from '@/config'

export type NewUser = {
  id: string
  email: string
}

export default function Register() {
  const { firebaseUser, signOut, getUserToken } = useContext(AuthContext)
  const navigate = useNavigate()

  const [{ data, loading, error }, execute] = useAxios<User, NewUser>(
    `${config.apiClient.BASE_URL}/user/users`,
    {
      manual: true
    }
  )

  const handleRegister = async (data: { id: string; email: string }) => {
    execute({
      method: 'post',
      data,
      headers: {
        Authorization: `Bearer ${await firebaseUser?.getIdToken()}`
      }
    })
  }

  useEffect(() => {
    if (error) {
      console.log(error)
    }
    if (!loading && data) {
      getUserToken()
      navigate('/')
    }
  }, [loading, data, error])

  useEffect(() => {
    if (!firebaseUser) {
      navigate('/sign-in')
    }
  }, [firebaseUser])

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        width: `min(400px, calc(100% - 50px))`,
        margin: 'auto',
        justifyContent: 'center',
        alignItems: 'stretch'
      }}>
      <Box sx={{ mb: '20px' }}>
        <Typography variant="h4" component="div">
          填寫會員註冊資料
        </Typography>
        <Typography variant="body1" component="div">
          {`(您目前使用: ${firebaseUser?.email ?? firebaseUser?.displayName})`}
        </Typography>
        <Button
          sx={{ padding: 0 }}
          onClick={() => {
            signOut()
          }}>
          登出
        </Button>
      </Box>
      <FormContainer
        defaultValues={{
          id: '',
          email: firebaseUser?.email ?? ''
        }}
        onSuccess={handleRegister}>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '15px' }}>
          <TextFieldElement
            name="email"
            label="Email"
            size="small"
            fullWidth
            type={'email'}
            required
            disabled={Boolean(firebaseUser?.email)}
          />
          <TextFieldElement
            name="id"
            label="ID (建立後即無法修改)"
            size="small"
            fullWidth
            validation={{
              pattern: /^[a-zA-Z][a-zA-Z0-9._-]{5,19}$/i
            }}
            parseError={error => {
              if (error.type === 'pattern')
                return '6-20個字元，只能使用英文、數字、底線(_)、連字號(-)、小數點(.)，且必須以英文開頭'
              return error.message ?? ''
            }}
            required
          />
          <Button variant="contained" type={'submit'} color={'primary'}>
            註冊
          </Button>
        </Box>
      </FormContainer>
      <Box sx={{ my: '10px' }}>
        <Link href="/">Home</Link>
      </Box>
    </Box>
  )
}

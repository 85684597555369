import AuthContext from '@/context/AuthContext'
import { useContext, useEffect, useState } from 'react'
import { makeUseAxios, Options, ResponseValues } from 'axios-hooks'
import LRU from 'lru-cache'
import axios, { AxiosRequestConfig } from 'axios'
import config from '@/config'
import { objectKeys } from '@/helper'

const axiosInstance = axios.create({
  url: config.apiClient.BASE_URL,
  timeout: config.apiClient.DEFAULT_TIMEOUT
})

const useAxios = makeUseAxios({
  axios: axiosInstance,
  cache: new LRU({ max: 10 }),
  defaultOptions: {
    manual: true,
    useCache: true,
    ssr: false,
    autoCancel: true
  }
})

function addBaseUrl(url?: string) {
  if (!url || url.startsWith('http')) return url
  return config.apiClient.BASE_URL + url
}

// merge the new request config with the old one and return a new one recursively
// if the property in the new one is undefined, return the property in the old one
// the merged object should also include the properties in the old one that are not in the new one
function mergeConfig<T>(oldConfig: T, newConfig: T) {
  const mergedConfig = { ...oldConfig }
  objectKeys(newConfig).forEach(key => {
    if (newConfig[key] instanceof FormData === true) {
      mergedConfig[key] = newConfig[key]
    } else if (
      typeof newConfig[key] === 'object' &&
      typeof oldConfig[key] === 'object'
    ) {
      mergedConfig[key] = mergeConfig(oldConfig[key], newConfig[key])
    } else {
      mergedConfig[key] = newConfig[key] ?? oldConfig[key]
    }
  })
  return mergedConfig
}

export default function useApiClient<
  TResponse = unknown,
  TBody = unknown,
  TError = unknown
>(
  path?: string,
  options?: Options
): [
  ResponseValues<TResponse, TBody, TError>,
  (req: AxiosRequestConfig<TBody> | undefined) => Promise<void>,
  () => void
] {
  const [execNeeded, setExecNeeded] = useState<boolean>(false)
  const [userToken, setUserToken] = useState<string | null>(null)
  const [userTokenLoading, setUserTokenLoading] = useState(false)
  const [reqConfig, setReqConfig] = useState<AxiosRequestConfig>({})
  const [loading, setLoading] = useState(false)
  const { getUserToken } = useContext(AuthContext)

  const axiosConfig: AxiosRequestConfig<TBody> | undefined = {
    url: path,
    headers: {
      Authorization: `Bearer ${userToken}`
    }
  }

  const [
    { data, loading: axiosLoading, error, response },
    executeAxios,
    manualCancel
  ] = useAxios<TResponse, TBody, TError>(axiosConfig, {
    ...options,
    manual: options?.manual ?? true
  })

  const execute = async (req: AxiosRequestConfig<TBody> | undefined) => {
    setLoading(true)
    setUserTokenLoading(true)
    const newUserToken = await getUserToken()

    if (req) {
      setReqConfig(req)
    }

    setUserToken(newUserToken)
    setExecNeeded(true)
    setUserTokenLoading(false)
  }

  useEffect(() => {
    if (execNeeded && !userTokenLoading && userToken) {
      const config = mergeConfig(axiosConfig, reqConfig)
      config.url = addBaseUrl(config.url)

      executeAxios(config)
      setExecNeeded(false)
    }
  }, [execNeeded, axiosConfig, reqConfig, userToken, userTokenLoading])

  useEffect(() => {
    if (loading && !axiosLoading) {
      setLoading(false)
    }
  }, [axiosLoading])

  return [
    {
      data,
      loading,
      error,
      response
    },
    execute,
    manualCancel
  ]
}

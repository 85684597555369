import AddIcon from '@mui/icons-material/Add'
import Box from '@mui/material/Box'
import ReviewPreviewCard from './ReviewPreviewCard'
import { useNavigate } from 'react-router-dom'
import useApiClient from '@/hook/ApiClient'
import { PublicRestaurantReview, RestaurantReview } from '../types'
import { useEffect, useContext, useState } from 'react'
import AuthContext from '@/context/AuthContext'
import { Button } from '@mui/material'

export default function OutlinedCard() {
  const navigate = useNavigate()

  const { user } = useContext(AuthContext)
  const [reviews, setReviews] = useState<
    (RestaurantReview | PublicRestaurantReview)[]
  >([])

  const [
    { data: reviewsResponse, loading: loadingReviews, error: getReviewsError },
    getReviews
  ] = useApiClient<{ reviews: PublicRestaurantReview[] }>(
    '/restaurant-review/reviews'
  )

  useEffect(() => {
    if (!user) return

    getReviews({
      method: 'get',
      params: {
        userId: user.id
        // TODO: pagination
      }
    })
  }, [user])

  useEffect(() => {
    if (!reviewsResponse) return

    setReviews(reviewsResponse.reviews)
  }, [reviewsResponse])

  if (loadingReviews) {
    return <Box>loading...</Box>
  }

  if (!reviewsResponse || getReviewsError) {
    return <Box>Error!!</Box>
  }

  return (
    <Box
      sx={{
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        justifyContent: 'center',
        alignItems: 'stretch'
      }}>
      <Button
        variant="outlined"
        size="large"
        sx={{ width: '250px', margin: '20px', fontWeight: 'bold' }}
        startIcon={<AddIcon />}
        onClick={() => {
          navigate('/restaurant-review-form')
        }}>
        新增餐飲評鑑
      </Button>

      {reviews.map(r => (
        <ReviewPreviewCard
          key={r.id}
          review={r}
          onDelete={id => {
            setReviews(reviews.filter(r => r.id !== id))
          }}
        />
      ))}
    </Box>
  )
}

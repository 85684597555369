import {
  ResourceId,
  ResourceActionId,
  ScopeId,
  PermissionString
} from '@/context/type'
import { User } from '@/context/type'

function parsePermission(
  permission: string
): [ResourceId, ResourceActionId, ScopeId] {
  const [resource, view, operation] = permission.split(':')
  return [
    resource as ResourceId,
    view as ResourceActionId,
    operation as ScopeId
  ]
}

export const checkPermissions = (
  permissions: PermissionString[],
  user: User | null
) => {
  return permissions.every(permission => {
    const [requestedResource, resourceRequestVerb, resourceRequestObject] =
      parsePermission(permission)
    return user?.roles.some(role =>
      objectKeys(role.permissions).some(
        resource =>
          (resource === '*' || resource === requestedResource) &&
          objectKeys(role.permissions[resource]).some(
            verb =>
              (verb === '*' || verb === resourceRequestVerb) &&
              role.permissions[resource][verb].some(
                object =>
                  object === '*' ||
                  object === resourceRequestObject ||
                  resourceRequestObject === ''
              )
          )
      )
    )
  })
}

export const objectKeys: <T>(obj: T) => Array<keyof T> = Object.keys

export const downloadFileFromBuffer = (
  buf: Buffer,
  mimeType: string,
  filename: string
) => {
  const blob = new Blob([buf], { type: mimeType })

  // create file link in browser's memory
  const href = URL.createObjectURL(blob)

  // create "a" HTLM element with href to file & click
  const link = document.createElement('a')
  link.href = href
  link.setAttribute('download', filename) //or any other extension
  document.body.appendChild(link)
  link.click()

  // clean up "a" element & remove ObjectURL
  document.body.removeChild(link)
  URL.revokeObjectURL(href)
}

export function downloadFileFromUri(uri: string) {
  const link = document.createElement('a')
  link.href = uri
  document.body.appendChild(link)
  link.click()
  document.body.removeChild(link)
}

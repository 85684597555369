import { Box, IconButton, useTheme } from '@mui/material'
import { useRef, useState, useEffect, forwardRef } from 'react'
import QrScanner from 'qr-scanner'
import LoadingIndicator from '@/component/LoadingIndicator'
import CameraswitchIcon from '@mui/icons-material/Cameraswitch'
import CancelIcon from '@mui/icons-material/Cancel'
import config from '@/config'
import { calculateScanRegion, drawScanRegion } from '../helper/canvas'
import { InvoiceQrCodeContent, parseEInvoiceQrCode } from '../helper/inovice'

export type CancelReason = 'timeout' | 'user'

export type Props = {
  onChange: (value: InvoiceQrCodeContent) => void
  cancel: (reason: CancelReason) => void
}

export default forwardRef<HTMLVideoElement, Props>(
  ({ onChange, cancel }, outerVideoRef) => {
    const videoRef = useRef<HTMLVideoElement | null>(null)
    const canvasRef = useRef<HTMLCanvasElement | null>(null)

    const [loading, setLoading] = useState(true)
    const [qrScanner, setQrScanner] = useState<QrScanner | null>(null)
    const [facingMode, setFacingMode] = useState<'user' | 'environment'>(
      'environment'
    )

    const theme = useTheme()

    const setOuterVideoRef = (v: HTMLVideoElement) => {
      videoRef.current = v
      if (typeof outerVideoRef === 'function') outerVideoRef(v)
      else if (outerVideoRef) outerVideoRef.current = v
    }

    useEffect(() => {
      const timeout = config.restaurantReview.QR_CODE_SCANNING_TIMEOUT

      const id = setTimeout(() => {
        cancel('timeout')
      }, timeout)

      return () => {
        clearTimeout(id)
      }
    }, [])

    const decodeHandler = (result: QrScanner.ScanResult) => {
      const content = parseEInvoiceQrCode(result.data)

      if (!content) return

      onChange(content)
    }

    useEffect(() => {
      if (!videoRef.current) return

      QrScanner['_disableBarcodeDetector'] = true

      const newQrScanner = new QrScanner(videoRef.current, decodeHandler, {
        highlightScanRegion: false,
        highlightCodeOutline: false,
        returnDetailedScanResult: true,
        preferredCamera: facingMode,
        calculateScanRegion: video => {
          const { qrCodeRegion } = calculateScanRegion(
            video.videoWidth,
            video.videoHeight
          )

          return qrCodeRegion
        }
      })

      setQrScanner(newQrScanner)

      const resizeHandler = () => {
        if (canvasRef.current && videoRef.current) {
          canvasRef.current.width = videoRef.current.offsetWidth
          canvasRef.current.height = videoRef.current.offsetHeight

          drawScanRegion(
            canvasRef.current,
            videoRef.current,
            theme.palette.primary.main
          )
        }
      }

      videoRef.current.addEventListener('resize', resizeHandler)

      newQrScanner.start().then(() => {
        setLoading(false)
      })

      return () => {
        newQrScanner.stop()
        videoRef.current?.removeEventListener('resize', resizeHandler)
      }
    }, [outerVideoRef])

    useEffect(() => {
      if (qrScanner) {
        qrScanner.setCamera(facingMode)
      }
    }, [facingMode])

    return (
      <Box
        sx={{
          width: '100%',
          height: '100%',
          m: 'auto',
          p: '0px',
          bgcolor: 'rgba(0, 0, 0, 0)',
          display: 'flex',
          flexDirection: 'column'
        }}>
        {loading && <LoadingIndicator open>載入中</LoadingIndicator>}

        {/** overlay video and canvas */}
        <Box
          style={{ overflow: 'hidden', position: 'relative', flex: '1 1 0' }}>
          <div
            style={{
              height: '100%',
              position: 'absolute',
              top: '50%',
              left: '50%',
              // use a div wrapper since the library adds 'transform: scaleX(-1);' to the video element
              transform: 'translate(-50%, -50%)'
            }}>
            <video
              ref={setOuterVideoRef}
              tabIndex={-1} // workaround for MUI trapfocus issue
              style={{
                height: '100%'
              }}
            />
            <canvas
              ref={canvasRef}
              style={{
                position: 'absolute',
                top: 0,
                left: 0,
                height: '100%',
                width: '100%'
              }}
            />
          </div>
        </Box>
        <Box
          sx={{
            flex: '0 0 80px',
            height: '80px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: theme.palette.common.black
          }}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              width: '50%',
              maxWidth: '300px'
            }}>
            <IconButton
              size="large"
              color="primary"
              onClick={() => {
                setFacingMode(
                  facingMode === 'environment' ? 'user' : 'environment'
                )
              }}>
              <CameraswitchIcon fontSize="inherit" />
            </IconButton>

            <IconButton
              size="large"
              color="error"
              onClick={() => {
                cancel('user')
              }}>
              <CancelIcon fontSize="inherit" />
            </IconButton>
          </Box>
        </Box>
      </Box>
    )
  }
)

import { FirebaseSignInProvider } from '@/context/type'
import LoadingButton from '@mui/lab/LoadingButton'
import { useTheme } from '@mui/material'
import { useContext } from 'react'
import AuthContext from '../context/AuthContext'

type SignInButtonProps = {
  providerId: FirebaseSignInProvider
  iconImgSrc: string
  color?: string
  backgroundColor?: string
}

const SignInButton: React.FC<SignInButtonProps> = ({
  providerId,
  iconImgSrc,
  color = '#000000',
  backgroundColor = '#FFFFFF',
  children
}) => {
  const { signIn, loading } = useContext(AuthContext)
  const theme = useTheme()

  return (
    <LoadingButton
      loading={loading}
      loadingPosition="start"
      variant="outlined"
      startIcon={<img src={iconImgSrc} width="32" />}
      sx={{
        margin: '5px',
        backgroundColor,
        color,
        borderColor: theme.palette.primary.contrastText,
        '&:hover': {
          color: theme.palette.common.white,
          backgroundColor: theme.palette.grey['600']
        }
      }}
      onClick={() => {
        signIn(providerId)
      }}>
      {children}
    </LoadingButton>
  )
}

export default SignInButton

import { Typography } from '@mui/material'
import TextField from '@mui/material/TextField'
import { useState, useEffect, forwardRef, ForwardedRef } from 'react'

export type Props = {
  onChange?: (value: string, error?: Error) => void
  value?: string
  display: boolean
  showingError: boolean
}

export default forwardRef(
  (
    { onChange: changeHandler, value: valueProp, display, showingError }: Props,
    ref: ForwardedRef<HTMLInputElement> | undefined
  ): JSX.Element => {
    const isValid = (v: string | null) =>
      !display || (v && /^([A-Z]{2}[0-9]{8})?$/.test(v))

    const errorMessage = '發票字軌格式應為 AB12345678'
    const defaultError = new Error(errorMessage)

    const [error, setError] = useState<Error | undefined>()
    const [touched, setTouched] = useState(false)

    const [value, setValue] = useState<string>('')

    useEffect(() => {
      if (valueProp === undefined) return

      setValue(valueProp)
    }, [valueProp])

    useEffect(() => {
      const e = isValid(value) ? undefined : defaultError
      setError(e)
      changeHandler?.(value, e)
    }, [value, display])

    if (!display) {
      return <></>
    }

    return (
      <>
        {(touched || showingError) && error && (
          <Typography
            variant="caption"
            color="error"
            sx={{ alignSelf: 'flex-start' }}>
            *{errorMessage}
          </Typography>
        )}
        <TextField
          id="invoice-number"
          ref={ref}
          label="發票號碼"
          placeholder="AB12345678"
          // defaultValue={value} // if user choose another country, this should not be reset
          value={value}
          type="text"
          error={(touched || showingError) && !!error}
          onChange={v => {
            setValue(v.target.value)
          }}
          onBlur={() => {
            setTouched(true)
          }}
        />
      </>
    )
  }
)

import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import CountrySelect from '../component/CountrySelect'
import moment from 'moment'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import {
  ReviewCreatingRequest,
  ReviewUpdatingRequest,
  Ratings,
  Restaurant
} from '../../types'
import { RefObject, useContext, useEffect, useRef, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import ImageUploadInput from '../component/ImageUploadInput'
import RestaurantSelect from '../component/RestaurantSelect'
import config from '@/config'
import AuthContext from '@/context/AuthContext'
import VisitedAtSelect from '../component/VisitedAtSelect'
import InvoiceNumberTextField from '../component/InvoiceNumberTextField'
import TotalPriceTextField from '../component/TotalPriceTextField'
import CommentsTextField from '../component/CommentsTextField'
import RatingInputGroup from '../component/RatingInputGroup'
import LoadingIndicator from '@/component/LoadingIndicator'
import SnackbarAlertContext from '@/context/SnackbarAlertContext'
import PeopleAmountSelect from '../component/PeopleAmountSelect'
import { Modal } from '@mui/material'
import InvoiceQrCodeScanner from '../qr-code-scanner/component/InvoiceQrCodeScanner'
import QrCodeScannerIcon from '@mui/icons-material/QrCodeScanner'
import { InvoiceQrCodeContent } from '../qr-code-scanner/helper/inovice'
import {
  useRestaurantReviewApi,
  RestaurantReviewApiEvent
} from '../hook/RestaurantReviewApi'
import { objectKeys } from '@/helper'

function findRestaurant(
  restaurantId: string | null,
  restaurants: Restaurant[]
): Restaurant | null {
  if (!restaurantId) return null
  return restaurants.find(r => r.id === restaurantId) ?? null
}

type ValidTable = Record<
  string,
  {
    name: string
    valid: boolean
    ref: RefObject<HTMLElement>
  }
>

export default function RestaurantForm(): JSX.Element {
  const defaultCountry = 'TW'
  const [country, setCountry] = useState<string | null>(defaultCountry)
  const [restaurantId, setRestaurantId] = useState<string | null>(null)
  const [invoiceNumber, setInvoiceNumber] = useState<string | null>(null)
  const [totalPrice, setTotalPrice] = useState<string>('')
  const [peopleAmount, setPeopleAmount] = useState<number>(2)
  const [visitedAt, setVisitedAt] = useState<string>(moment().toISOString())
  const [ratings, setRatings] = useState<Ratings>({
    healthy: 0,
    aesthetics: 0,
    environment: 0,
    specialty: 0,
    cooking: 0,
    inspirit: 0
  })
  const [comments, setComments] = useState<string>('')
  const [previousReceiptImageUrl, setPreviousReceiptImageUrl] =
    useState<string>()
  const [previousDiningImageUrl, setPreviousDiningImageUrl] = useState<string>()
  const [receiptImageFile, setReceiptImageFile] = useState<File>()
  const [diningImageFile, setDiningImageFile] = useState<File>()

  const { user } = useContext(AuthContext)
  const { showSnackbarAlert } = useContext(SnackbarAlertContext)
  const navigate = useNavigate()
  const { reviewId } = useParams<{ reviewId?: string }>()
  const editMode = reviewId !== undefined

  const [showingError, setShowingError] = useState<boolean>(false)

  const receiptImageInputRef = useRef<HTMLDivElement>(null)
  const diningImageInputRef = useRef<HTMLDivElement>(null)
  const countrySelectInputRef = useRef<HTMLInputElement>(null)
  const visitedAtInputRef = useRef<HTMLInputElement>(null)
  const restaurantSelectInputRef = useRef<HTMLInputElement>(null)
  const invoiceNumberInputRef = useRef<HTMLInputElement>(null)
  const totalPriceInputRef = useRef<HTMLInputElement>(null)
  const ratingsGroupRef = useRef<HTMLDivElement>(null)
  const commentsInputRef = useRef<HTMLInputElement>(null)

  const [validTable, setValidTable] = useState<ValidTable>({
    receiptImage: {
      name: '發票照片',
      valid: false,
      ref: receiptImageInputRef
    },
    diningImage: {
      name: '用餐照片',
      valid: false,
      ref: diningImageInputRef
    },
    country: {
      name: '國家',
      valid: false,
      ref: countrySelectInputRef
    },
    visitedAt: {
      name: '用餐時間',
      valid: false,
      ref: visitedAtInputRef
    },
    restaurantId: {
      name: '餐廳',
      valid: false,
      ref: restaurantSelectInputRef
    },
    invoiceNumber: {
      name: '發票號碼',
      valid: false,
      ref: invoiceNumberInputRef
    },
    totalPrice: {
      name: '總價',
      valid: false,
      ref: totalPriceInputRef
    },
    ratings: {
      name: '評分',
      valid: false,
      ref: ratingsGroupRef
    },
    comments: {
      name: '用餐心得',
      valid: false,
      ref: commentsInputRef
    }
  })

  useEffect(() => {
    setValidTable(t => {
      t.receiptImage.valid = editMode
      t.diningImage.valid = editMode
      return t
    })
  }, [editMode])

  /* restaurant review api */
  const {
    loading,
    saving,
    event,
    saveReview,
    activeRestaurants,
    review,
    reviewStatus
  } = useRestaurantReviewApi({
    user,
    reviewId,
    country
  })

  // calculate sum of the stars in review
  const totalStars =
    (editMode && review
      ? objectKeys(review.ratings).reduce(
          (acc, key) => acc + review.ratings[key],
          0
        )
      : config.restaurantReview.STARS_RECEIVED_AMOUNT_PER_REVIEW) +
    (reviewStatus?.starsLeft ?? 0)

  useEffect(() => {
    if (loading || saving) return

    const eventTable: Record<
      RestaurantReviewApiEvent,
      {
        back: boolean
        alert: Parameters<typeof showSnackbarAlert>[0]
      }
    > = {
      'review-limit-reached': {
        back: true,
        alert: {
          content: '本月份的美食評鑑均已上傳完成，感謝您的參與！',
          severity: 'info'
        }
      },
      'review-retrieving-error': {
        back: true,
        alert: {
          content: '評鑑資料讀取錯誤，請稍候再試',
          severity: 'error'
        }
      },
      'review-saving-error': {
        /* TODO: show form errors. ex. duplicate keys */
        back: false,
        alert: {
          content: `${editMode ? '更新' : '新增'}失敗，表單內容有誤！`,
          severity: 'error'
        }
      },
      'review-saving-success': {
        back: true,
        alert: {
          content: `${editMode ? '更新' : '新增'}成功！`,
          severity: 'success'
        }
      }
    }

    if (!event) return

    const { back, alert } = eventTable[event]

    if (back) navigate(-1)
    if (alert) showSnackbarAlert(alert)
  }, [event, loading, saving])

  useEffect(() => {
    if (!review) return

    // set fields
    setCountry(review.restaurant.country.isoCode)
    setInvoiceNumber(review.invoiceNumber ?? null)
    setTotalPrice(
      // remove trailing zeros
      review.totalPrice.replace(/(\.[0-9]*?)0+$/, '$1').replace(/\.$/, '')
    )
    setPeopleAmount(review.peopleAmount)
    setRestaurantId(review.restaurant.id)
    setVisitedAt(review.visitedAt)
    setRatings(review.ratings)
    setComments(review.comments)

    setPreviousReceiptImageUrl(review.receiptImageUrl)
    setPreviousDiningImageUrl(review.diningImageUrl)
  }, [review])

  useEffect(() => {
    const selectedRestaurant = activeRestaurants.find(
      r => r.id === restaurantId
    )
    setValidTable(t => {
      t.restaurantId.valid = !!selectedRestaurant
      return t
    })
  }, [activeRestaurants, restaurantId])

  /* qr code scanner */
  const [open, setOpen] = useState(false)
  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)
  const videoRef = useRef<HTMLVideoElement>(null)

  const qrCodeScannerChangeHandler = ({
    invoiceNumber,
    date,
    totalPrice,
    buyerGuiNumber,
    sellerGuiNumber
  }: InvoiceQrCodeContent) => {
    handleClose()

    const companyGuiNumber = '50780754'

    if (buyerGuiNumber !== companyGuiNumber) {
      showSnackbarAlert({
        content: `買方統編應為: ${companyGuiNumber}`,
        severity: 'error',
        autoHideDuration: 3000
      })
      return
    }

    setCountry('TW')
    setInvoiceNumber(invoiceNumber)
    setVisitedAt(
      moment((parseInt(date) + 19110000).toString(), 'YYYYMMDD')
        .add(12, 'hours')
        .add(Math.floor(Math.random() * 1000), 'milliseconds')
        .toISOString()
    )
    setTotalPrice(totalPrice.toString())

    setSellerGuiNumber(sellerGuiNumber)

    showSnackbarAlert({
      content: '資料擷取成功',
      severity: 'success',
      autoHideDuration: 3000
    })
  }

  const qrCodeScannerCancelHandler = (reason: string) => {
    showSnackbarAlert({
      content: reason === 'user' ? '已取消掃描' : '資料擷取失敗',
      severity: reason === 'user' ? 'info' : 'error',
      autoHideDuration: 3000
    })
    handleClose()
  }

  const [sellerGuiNumber, setSellerGuiNumber] = useState<string | null>(null)

  useEffect(() => {
    if (!sellerGuiNumber) return

    const selectedRestaurant = activeRestaurants.find(
      r => r.businessAdministrationNumber === sellerGuiNumber
    )
    setRestaurantId(selectedRestaurant?.id ?? null)
    if (!selectedRestaurant)
      setValidTable(t => {
        t.restaurantId.valid = false
        return t
      })
  }, [activeRestaurants, sellerGuiNumber])

  /* form submit */
  const submitForm = (): void => {
    if (!restaurantId) return

    if (!editMode && (!receiptImageFile || !diningImageFile)) return

    const commonRequestFileds = {
      restaurantId,
      totalPrice,
      peopleAmount,
      visitedAt: visitedAt ?? '',
      ratings,
      comments,
      ...(country === 'TW' &&
        invoiceNumber && {
          invoiceNumber
        })
    }

    const req: ReviewUpdatingRequest | ReviewCreatingRequest = editMode
      ? {
          id: reviewId,
          userId: user?.id,
          ...commonRequestFileds
        }
      : commonRequestFileds

    const formData = new FormData()

    const reviewBlob = new Blob([JSON.stringify(req)], {
      type: 'application/json'
    })
    formData.append('review', reviewBlob)
    if (receiptImageFile)
      formData.append('receiptImage', receiptImageFile, receiptImageFile.name)
    if (diningImageFile)
      formData.append('diningImage', diningImageFile, diningImageFile.name)

    const reqConfig = {
      url: `/restaurant-review/reviews${editMode ? `/${reviewId}` : ''}`,
      method: editMode ? 'put' : 'post',
      data: formData,
      headers: {
        'Content-Type': 'multipart/form-data'
      },
      timeout: config.apiClient.FILE_UPLOAD_TIMEOUT
    }

    saveReview(reqConfig)
  }

  const submitButtonClickHandler = () => {
    const firstInvalidField = Object.keys(validTable).find(
      k => !validTable[k].valid
    )
    if (firstInvalidField) {
      validTable[firstInvalidField].ref.current?.scrollIntoView({
        behavior: 'smooth',
        block: 'center'
      })
      setShowingError(true)
      showSnackbarAlert({
        content: `「${validTable[firstInvalidField].name}」內容有誤！請再次檢查欄位謝謝！`,
        severity: 'error'
      })
    } else {
      submitForm()
    }
  }

  return (
    <div>
      <LoadingIndicator open={loading}>
        <Typography variant="h6" component="div">
          評鑑資料讀取中...
        </Typography>
      </LoadingIndicator>

      <LoadingIndicator open={saving}>
        <Typography variant="h6" component="div">
          資料上傳中...
        </Typography>
      </LoadingIndicator>

      <h2>餐飲評鑑心得</h2>

      <Modal open={open} onClose={handleClose} sx={{ display: 'flex' }}>
        <InvoiceQrCodeScanner
          cancel={qrCodeScannerCancelHandler}
          onChange={qrCodeScannerChangeHandler}
          ref={videoRef}
        />
      </Modal>

      <Box
        sx={{
          maxWidth: '80%',
          margin: 'auto',
          padding: '30px 0px',
          display: 'flex',
          flexDirection: 'column'
        }}>
        <Stack spacing={2}>
          <Typography variant="h6" component="div" sx={{ textAlign: 'left' }}>
            發票/收據
          </Typography>

          <Box
            sx={{
              display: 'flex',
              width: '100%',
              flexWrap: 'wrap',
              gap: '15px',
              '>*': {
                flex: '1 1 300px'
              }
            }}>
            <ImageUploadInput
              inputId="select-receipt-image"
              ref={receiptImageInputRef}
              title="發票照片 (檔案須小於 10MB)"
              showingError={showingError}
              placeholderImageUrl={previousReceiptImageUrl}
              allowEmpty={editMode}
              onFileChange={file => {
                setReceiptImageFile(file)
                setValidTable(t => {
                  t.receiptImage.valid = editMode || !!file
                  return t
                })
              }}
            />
            <ImageUploadInput
              inputId="select-dining-image"
              ref={diningImageInputRef}
              title="用餐照片 (檔案須小於 10MB)"
              showingError={showingError}
              placeholderImageUrl={previousDiningImageUrl}
              allowEmpty={editMode}
              onFileChange={file => {
                setDiningImageFile(file)
                setValidTable(t => {
                  t.diningImage.valid = editMode || !!file
                  return t
                })
              }}
            />
          </Box>

          <br />

          <Button
            variant="outlined"
            startIcon={<QrCodeScannerIcon />}
            onClick={handleOpen}
            color="primary">
            掃描電子發票 QR Code
          </Button>

          <CountrySelect
            ref={countrySelectInputRef}
            defaultValue={defaultCountry}
            showingError={showingError}
            value={country}
            onChange={v => {
              setCountry(v)
              setRestaurantId(null)
              setValidTable(t => {
                t.country.valid = !!v
                return t
              })
            }}
          />

          <VisitedAtSelect
            ref={visitedAtInputRef}
            value={visitedAt}
            showingError={showingError}
            onChange={(value, error) => {
              setVisitedAt(value)
              setValidTable(t => {
                t.visitedAt.valid = !error
                return t
              })
            }}
          />

          <RestaurantSelect
            ref={restaurantSelectInputRef}
            restaurants={country ? activeRestaurants : ([] as Restaurant[])}
            loading={loading}
            restaurantId={restaurantId}
            showingError={showingError}
            onChange={(v, error) => {
              if (loading) return

              setRestaurantId(v)
              setValidTable(t => {
                t.restaurantId.valid = !error
                return t
              })
            }}
          />

          <InvoiceNumberTextField
            ref={invoiceNumberInputRef}
            value={invoiceNumber ?? ''}
            showingError={showingError}
            onChange={(value, error) => {
              setInvoiceNumber(value)
              setValidTable(t => {
                t.invoiceNumber.valid = !error
                return t
              })
            }}
            display={country === 'TW'}
          />

          <TotalPriceTextField
            ref={totalPriceInputRef}
            showingError={showingError}
            currency={
              findRestaurant(restaurantId, activeRestaurants)?.currency
                .isoCode ?? 'TWD'
            }
            value={totalPrice}
            onChange={(value, error) => {
              setTotalPrice(value)
              setValidTable(t => {
                t.totalPrice.valid = !error && parseInt(value) > 0
                return t
              })
            }}
          />

          <br />

          <PeopleAmountSelect
            defaultValue={peopleAmount}
            min={1}
            max={30}
            value={peopleAmount}
            onChange={value => {
              setPeopleAmount(value)
            }}
          />

          <Typography variant="h6" component="div" sx={{ textAlign: 'left' }}>
            餐廳評價
          </Typography>

          <RatingInputGroup
            ref={ratingsGroupRef}
            showingError={showingError}
            totalStars={totalStars}
            value={ratings}
            onChange={(value, error) => {
              setRatings(value)
              setValidTable(t => {
                t.ratings.valid = !error
                return t
              })
            }}
          />

          <CommentsTextField
            ref={commentsInputRef}
            showingError={showingError}
            minimumLength={config.restaurantReview.MINIMUM_COMMENT_LENGTH}
            value={comments}
            onChange={(value, error) => {
              setComments(value)
              setValidTable(t => {
                t.comments.valid = !error
                return t
              })
            }}
          />

          <Button variant="contained" onClick={submitButtonClickHandler}>
            送出
          </Button>
        </Stack>
      </Box>
    </div>
  )
}

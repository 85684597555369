import { useState, useEffect, ForwardedRef, forwardRef } from 'react'
import Autocomplete from '@mui/material/Autocomplete'
import TextField from '@mui/material/TextField'
import { Restaurant } from '../../types'
import { Typography, Box } from '@mui/material'

export type Props = {
  onChange?: (value: string | null, error?: Error) => void
  restaurants: Restaurant[]
  restaurantId?: string | null
  loading: boolean
  showingError: boolean
}

export default forwardRef(
  (
    {
      onChange: changeHandler,
      restaurants,
      restaurantId: restaurantIdProp,
      loading,
      showingError
    }: Props,
    ref: ForwardedRef<HTMLInputElement> | undefined
  ): JSX.Element => {
    const errorMessage = '請選擇餐廳'
    const defaultError = new Error(errorMessage)

    const [error, setError] = useState<Error | undefined>()
    const [touched, setTouched] = useState(false)

    const [restaurantId, setRestaurantId] = useState<string | null>(null)

    useEffect(() => {
      if (restaurantIdProp === undefined) return

      setRestaurantId(restaurantIdProp)
    }, [restaurantIdProp])

    useEffect(() => {
      const e = restaurantId ? undefined : defaultError
      setError(e)
      changeHandler?.(restaurantId, e)
    }, [restaurantId])

    return (
      <>
        {(touched || showingError) && error && (
          <Typography
            variant="caption"
            color="error"
            sx={{ alignSelf: 'flex-start', marginVertical: 0 }}>
            *{errorMessage}
          </Typography>
        )}
        <Autocomplete
          id="restaurant-select"
          sx={{ margin: '0px 0px' }}
          options={restaurants.sort((a, b) => {
            return !a.distance || !b.distance
              ? 0
              : parseFloat(a.distance) - parseFloat(b.distance)
          })}
          autoHighlight
          getOptionLabel={option => option.name}
          onChange={(event, value) => {
            setRestaurantId(value && value.id)
          }}
          value={restaurants.find(r => r.id === restaurantId) ?? null}
          disabled={loading}
          renderInput={params => (
            <TextField
              ref={ref}
              {...params}
              label={loading ? '載入中...' : '選擇餐廳'}
              error={(touched || showingError) && restaurantId === undefined}
              inputProps={{
                ...params.inputProps
              }}
              onBlur={() => {
                setTouched(true)
              }}
            />
          )}
          renderOption={(props, option) => (
            <Box
              component="li"
              sx={{ '& > img': { mr: 2, flexShrink: 0 } }}
              {...props}
              key={option.id}>
              {option.name} {option.distance && `(${option.distance}km)`}
            </Box>
          )}
        />
      </>
    )
  }
)

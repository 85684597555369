import { RatingDimension, Ratings } from '@/membership/types'
import { Typography, Box } from '@mui/material'
import { useState, useEffect, ForwardedRef, forwardRef } from 'react'
import RatingInput from './RatingInput'

const reviewDimensions: RatingDimension[] = [
  {
    id: 'healthy',
    title: '健康',
    description: '食材、食材說明、烹調方法'
  },
  {
    id: 'aesthetics',
    title: '美學',
    description: '擺盤、呈現方式、裝飾'
  },
  {
    id: 'environment',
    title: '環境',
    description: '風景、空間感、服務態度、座椅舒適性、背景音樂'
  },
  {
    id: 'specialty',
    title: '獨特',
    description: '是否有特色、有別於一般餐廳'
  },
  {
    id: 'cooking',
    title: '細緻',
    description: '口感是否柔和、料理技術、味覺豐富程度等'
  },
  {
    id: 'inspirit',
    title: '念力',
    description: '廚師是否用心、體貼客人、讓人感覺到滿滿正能量'
  }
]

export type Props = {
  onChange?: (value: Ratings, error?: Error) => void
  totalStars: number
  value?: Ratings
  showingError: boolean
}

const calculateNumberOfSelectedStars = (ratings: Ratings): number =>
  Object.values(ratings).reduce((sum, amount) => sum + amount, 0)

export default forwardRef(
  (
    {
      onChange: changeHandler,
      totalStars,
      value: valueProp,
      showingError
    }: Props,
    ref: ForwardedRef<HTMLDivElement> | undefined
  ): JSX.Element => {
    const isValid = (ratings: Ratings) => {
      const numberOfSelectedStars = calculateNumberOfSelectedStars(ratings)
      return totalStars >= numberOfSelectedStars && numberOfSelectedStars > 0
    }

    const errorMessage = `評價總星星數須介於 1 到 ${totalStars}`
    const defaultError = new Error(errorMessage)

    const [error, setError] = useState<Error | undefined>()
    const [touched, setTouched] = useState(false)

    const [value, setValue] = useState<Ratings>(
      valueProp ?? {
        healthy: 0,
        aesthetics: 0,
        environment: 0,
        specialty: 0,
        cooking: 0,
        inspirit: 0
      }
    )

    useEffect(() => {
      if (valueProp === undefined) return

      setValue(valueProp)
    }, [valueProp])

    useEffect(() => {
      const e = isValid(value) ? undefined : defaultError
      setError(e)
      changeHandler?.(value, e)
    }, [value])

    return (
      <Box ref={ref}>
        {(touched || showingError) && error && (
          <Typography
            variant="caption"
            color="error"
            sx={{ alignSelf: 'flex-end' }}>
            *{errorMessage}
          </Typography>
        )}
        <Typography
          variant="subtitle1"
          component="div"
          sx={{ textAlign: 'right' }}>
          已選 {calculateNumberOfSelectedStars(value)}/{totalStars} 顆星星
        </Typography>
        {reviewDimensions.map(dimension => (
          <RatingInput
            dimension={dimension}
            key={dimension.id}
            value={value[dimension.id]}
            onChange={v => {
              const ratings: Ratings = { ...value }
              ratings[dimension.id] = v
              setTouched(true)
              setValue(ratings)
            }}
          />
        ))}
      </Box>
    )
  }
)

import { createContext } from 'react'
import { Restaurant, FormMode } from '@/restaurant/types'

type RestaurantFormContextProps = {
  formOpened: boolean
  formMode: FormMode
  restaurant: Restaurant | undefined
  restaurants: Restaurant[]
  openForm: (formMode: FormMode, restaurant?: Restaurant) => void
  closeForm: () => void
  setRestaurants: React.Dispatch<React.SetStateAction<Restaurant[]>>
}

const RestaurantFormContext = createContext<RestaurantFormContextProps>({
  formOpened: false,
  formMode: 'CREATE',
  restaurant: undefined,
  restaurants: [],
  openForm: () => undefined,
  closeForm: () => undefined,
  setRestaurants: () => undefined
})

export default RestaurantFormContext

import { useState } from 'react'
import RestaurantFormContext from './RestaurantFormContext'
import { Restaurant, FormMode } from '@/restaurant/types'

const RestaurantFormProvider: React.FC<{
  children?: JSX.Element
}> = props => {
  const [formOpened, setFormOpened] = useState(false)
  const [formMode, setFormMode] = useState<FormMode>('CREATE')
  const [restaurant, setRestaurant] = useState<Restaurant | undefined>(
    undefined
  )
  const [restaurants, setRestaurants] = useState<Restaurant[]>([])
  const openForm = (formMode: FormMode, restaurant?: Restaurant) => {
    setFormOpened(true)
    setFormMode(formMode)
    if (restaurant) setRestaurant(restaurant)
    else setRestaurant(undefined)
  }
  const closeForm = () => setFormOpened(false)

  const contextValue = {
    formOpened,
    formMode,
    restaurant,
    restaurants,
    openForm,
    closeForm,
    setRestaurants
  }

  return (
    <RestaurantFormContext.Provider value={contextValue}>
      {props.children}
    </RestaurantFormContext.Provider>
  )
}

export default RestaurantFormProvider

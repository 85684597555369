export type Region = {
  x: number
  y: number
  width: number
  height: number
}

export const calculateScanRegion = (width: number, height: number) => {
  const heightPercentage = 0.99
  const heightWidthRatio = 0.55

  // calculate receipt region
  const receiptRegion = {
    x: 0,
    y: 0,
    width: 0,
    height: 0
  }
  receiptRegion.height = height * heightPercentage
  receiptRegion.width = receiptRegion.height * heightWidthRatio
  receiptRegion.x = width / 2 - receiptRegion.width / 2
  receiptRegion.y = height / 2 - receiptRegion.height / 2

  // calculate qrCode region
  const qrCodeRegion = {
    x: 0,
    y: 0,
    width: 0,
    height: 0
  }

  // qrCode should be 45% of the receipt width
  qrCodeRegion.width = receiptRegion.width * 0.45
  qrCodeRegion.height = qrCodeRegion.width

  // qrCode should be located at the left 1/4 of the receipt
  qrCodeRegion.x =
    receiptRegion.x + receiptRegion.width * 0.25 - qrCodeRegion.width / 2

  // qrCode should be located at the bottom 1/3 of the receipt
  qrCodeRegion.y =
    receiptRegion.y + receiptRegion.height * 0.66 - qrCodeRegion.height / 2

  return {
    receiptRegion,
    qrCodeRegion
  }
}

const flipCanvasRegion = (
  { x, y, width, height }: Region,
  fullWidth: number
) => {
  return {
    x: fullWidth - x - width,
    y,
    width,
    height
  }
}

type QrCodeRegion = {
  x: number
  y: number
  width: number
  height: number
}

const drawQrCodeRegion = (
  context: CanvasRenderingContext2D,
  qrCodeRegion: QrCodeRegion,
  strokeColor: string
) => {
  const extendedLength = 15
  const radius = 5

  const { x, y, width, height } = qrCodeRegion

  context.beginPath()
  context.lineWidth = 4
  context.strokeStyle = strokeColor

  // top right
  context.moveTo(x + width - radius - extendedLength, y)
  context.arcTo(x + width, y, x + width, y + radius, radius)
  context.lineTo(x + width, y + extendedLength + radius)

  // bottom right
  context.moveTo(x + width, y + height - radius - extendedLength)
  context.arcTo(x + width, y + height, x + width - radius, y + height, radius)
  context.lineTo(x + width - radius - extendedLength, y + height)

  // bottom left
  context.moveTo(x + radius + extendedLength, y + height)
  context.arcTo(x, y + height, x, y + height - radius, radius)
  context.lineTo(x, y + height - radius - extendedLength)

  // top left
  context.moveTo(x, y + radius + extendedLength)
  context.arcTo(x, y, x + radius, y, radius)
  context.lineTo(x + radius + extendedLength, y)

  context.stroke()
}

export const drawScanRegion = (
  canvas: HTMLCanvasElement,
  videoElement: HTMLVideoElement,
  strokeColor: string
) => {
  const context = canvas.getContext('2d')

  const { receiptRegion, qrCodeRegion } = calculateScanRegion(
    canvas.width,
    canvas.height
  )

  const { x, y, width, height } = receiptRegion

  if (context) {
    // draw receipt region
    context.beginPath()
    context.lineWidth = 3
    context.strokeStyle = strokeColor
    context.setLineDash([15, 5])
    context.rect(x, y, width, height)
    context.stroke()
    context.setLineDash([])

    const getScale = () => {
      const style = window.getComputedStyle(videoElement)
      return style.transform.match(/matrix.*\((.+)\)/)?.[1]?.split(', ')?.[0]
    }

    const [leftQrCodeRegion, rightQrCodeRegion] =
      getScale() === '-1'
        ? [flipCanvasRegion(qrCodeRegion, canvas.width), qrCodeRegion]
        : [qrCodeRegion, flipCanvasRegion(qrCodeRegion, canvas.width)]

    drawQrCodeRegion(context, leftQrCodeRegion, strokeColor)
    drawQrCodeRegion(context, rightQrCodeRegion, strokeColor)
  }
}

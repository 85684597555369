import useApiClient from '@/hook/ApiClient'
import {
  RestaurantReview,
  UserReviewStatus,
  Restaurant
} from '@/membership/types'
import { User } from '@/context/type'
import moment from 'moment'
import { useState, useEffect } from 'react'
import useGeolocation from 'react-use/lib/useGeolocation'

export type RestaurantReviewApiEvent =
  | 'review-saving-success'
  | 'review-saving-error'
  | 'review-limit-reached'
  | 'review-retrieving-error'

function toRadians(degrees: number): number {
  return degrees * (Math.PI / 180)
}

function haversineDistance(
  lat1: number,
  lon1: number,
  lat2: number,
  lon2: number
): number {
  const earthRadiusKm = 6371

  const dLat = toRadians(lat2 - lat1)
  const dLon = toRadians(lon2 - lon1)

  lat1 = toRadians(lat1)
  lat2 = toRadians(lat2)

  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.sin(dLon / 2) * Math.sin(dLon / 2) * Math.cos(lat1) * Math.cos(lat2)
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a))
  return earthRadiusKm * c
}

export function useRestaurantReviewApi({
  user,
  reviewId,
  country
}: {
  user: User | null
  reviewId?: string
  country: string | null
}) {
  const [event, setEvent] = useState<RestaurantReviewApiEvent>()
  const editMode = !!reviewId

  // retrieve review
  const [
    {
      data: reviewRetrievingResponse,
      loading: retrievingReview,
      error: reviewRetrievingError
    },
    getReview
  ] = useApiClient<{ review: RestaurantReview }>()

  // retrieve review status
  const [
    {
      data: reviewStatusRetrievingResponse,
      loading: retrievingReviewStatus,
      error: reviewStatusRetrievingError
    },
    getReviewStatus
  ] = useApiClient<{ status: UserReviewStatus }>()

  useEffect(() => {
    if (!user) return

    getReviewStatus({
      url: `/restaurant-review/users/${user.id}/status`,
      params: {
        period: moment().format('YYYY-MM')
      }
    })

    if (!reviewId) return

    getReview({
      url: `/restaurant-review/reviews/${reviewId}`
    })
  }, [])

  useEffect(() => {
    if (
      reviewStatusRetrievingResponse &&
      reviewStatusRetrievingResponse.status.reviewsLeft <= 0 &&
      !editMode
    )
      setEvent('review-limit-reached')
  }, [reviewStatusRetrievingResponse])

  // save review
  const [
    {
      data: reviewSavingResponse,
      loading: savingReview,
      error: reviewSavingError
    },
    saveReview
  ] = useApiClient<{ review: RestaurantReview }>()

  useEffect(() => {
    if (savingReview) return

    if (reviewSavingError) {
      setEvent('review-saving-error')
      return
    }

    if (!reviewSavingResponse) return

    setEvent('review-saving-success')
  }, [reviewSavingResponse, savingReview, reviewSavingError])

  // retrieve active restaurants
  const [
    {
      data: activeRestaurantsRetrievingResponse,
      loading: retrievingActiveRestaurants,
      error: activeRestaurantsRetrievingError
    },
    getActiveRestaurants
  ] = useApiClient<{ restaurants: Restaurant[] }>(
    '/restaurant-review/restaurants'
  )

  const [activeRestaurants, setActiveRestaurants] = useState<Restaurant[]>([])

  const { loading: locationLoading, longitude, latitude } = useGeolocation()

  useEffect(() => {
    if (!activeRestaurantsRetrievingResponse) return

    const restaurantsWithDistance =
      activeRestaurantsRetrievingResponse.restaurants.map(restaurant => {
        return {
          ...restaurant,
          distance: haversineDistance(
            latitude ?? 0,
            longitude ?? 0,
            restaurant.mapLocation.latitude,
            restaurant.mapLocation.longitude
          ).toFixed(3)
        }
      })

    setActiveRestaurants(restaurantsWithDistance)
  }, [activeRestaurantsRetrievingResponse, locationLoading])

  const defaultCountry = 'TW'

  useEffect(() => {
    getActiveRestaurants({
      method: 'GET',
      params: {
        country: country ?? defaultCountry
      }
    })
  }, [country])

  useEffect(() => {
    if (
      reviewRetrievingError ||
      reviewStatusRetrievingError ||
      activeRestaurantsRetrievingError
    )
      setEvent('review-retrieving-error')
  }, [
    reviewRetrievingError,
    reviewStatusRetrievingError,
    activeRestaurantsRetrievingError
  ])

  return {
    loading:
      retrievingReview || retrievingReviewStatus || retrievingActiveRestaurants,
    saving: savingReview,
    event,
    saveReview,
    activeRestaurants,
    review: reviewRetrievingResponse?.review,
    reviewStatus: reviewStatusRetrievingResponse?.status
  }
}

import { Backdrop, CircularProgress } from '@mui/material'

export type Props = {
  open: boolean
  children?: JSX.Element | string
}

const LoadingIndicator: React.FC<Props> = ({ open, children }) => {
  return (
    <Backdrop
      sx={{
        flexDirection: 'column',
        gap: 2,
        color: '#fff',
        zIndex: theme => theme.zIndex.drawer + 1
      }}
      open={open}>
      <CircularProgress color="inherit" />
      {children}
    </Backdrop>
  )
}

export default LoadingIndicator

import { Config } from './types'

const config: Config = {
  ENV: 'local',
  version: '0.1.0',
  ALLOW_FACEBOOK_SIGNIN: true,
  apiClient: {
    BASE_URL: 'http://localhost:8080/restful/v1',
    DEFAULT_TIMEOUT: 10000,
    FILE_UPLOAD_TIMEOUT: 60 * 1000
  },
  // Your web app's Firebase configuration
  // For Firebase JS SDK v7.20.0 and later, measurementId is optional
  firebaseConfig: {
    apiKey: 'AIzaSyDaXmLlW4UuGppsI2ETK1q8MHsBSoAb4QM',
    authDomain: 'project-mars-app.firebaseapp.com',
    projectId: 'project-mars-app',
    storageBucket: 'project-mars-app.appspot.com',
    messagingSenderId: '1023851476168',
    appId: '1:1023851476168:web:84bbf8178b5265f9a3e1fd',
    measurementId: 'G-HPL9C2KB4G'
  },
  restaurantReview: {
    STARS_RECEIVED_AMOUNT_PER_REVIEW: 8,
    MINIMUM_COMMENT_LENGTH: 100,
    QR_CODE_SCANNING_TIMEOUT: 15000
  }
}

export default config

import { Box } from '@mui/material'

import axios from 'axios'
import { useEffect, useState } from 'react'
import ReactMarkdown from 'react-markdown'

export default function TermsAndConditions() {
  const [termsAndConditionsText, setTermsAndConditionsText] = useState('')

  useEffect(() => {
    axios
      .get(
        'https://storage.googleapis.com/membership-public/terms-and-conditions.md',
        {
          headers: {
            Accept: 'application/json, text/plain, text/markdown, *.*'
          }
        }
      )
      .then(res => {
        setTermsAndConditionsText(res.data)
      })
  }, [])

  return (
    <Box style={{ margin: 30, textAlign: 'left' }}>
      <ReactMarkdown children={termsAndConditionsText} />
    </Box>
  )
}

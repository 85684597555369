import { Route, Routes, Link } from 'react-router-dom'

import Membership from './membership/page/Membership'
import Marster from './page/Marster'
import Home from './page/Home'
import Layout from './component/Layout'
import MarsLife from './page/MarsLife'
import Collections from './page/Collections'
import Academy from './page/Academy'
import JoinUs from './page/JoinUs'
import SignIn from './page/SignIn'
import Console from './console/page/Console'
import RequireAuth from './component/RequireAuth'
import RestaurantReviewForm from './membership/restaurant-review-form/page/RestaurantReviewForm'
import Register from './page/Register'
import Restaurant from './restaurant/page/Restaurant'
import Privacy from './page/Privacy'
import TermsAndConditions from './page/TermsAndConditions'
import Faq from './page/Faq'

export default function AppRoutes() {
  return (
    <Routes>
      <Route path="privacy" element={<Privacy />} />
      <Route path="terms-and-conditions" element={<TermsAndConditions />} />
      <Route path="faq" element={<Faq />} />

      <Route path="terms-and-conditions" element={<TermsAndConditions />} />
      <Route path="/sign-in" element={<SignIn />} />
      <Route path="/register" element={<Register />} />
      <Route path="/" element={<Layout />}>
        <Route index element={<Home />} />

        <Route
          path="membership"
          element={
            <RequireAuth>
              <Membership />
            </RequireAuth>
          }
        />
        <Route
          path="restaurant-review-form"
          element={
            <RequireAuth>
              <RestaurantReviewForm />
            </RequireAuth>
          }
        />
        <Route
          path="restaurant-review-form/:reviewId"
          element={
            <RequireAuth>
              <RestaurantReviewForm />
            </RequireAuth>
          }
        />
        <Route
          path="restaurant-review-report"
          element={
            <RequireAuth
              requiredPermissions={['restaurant-review-finance:get:']}>
              <Console />
            </RequireAuth>
          }
        />
        <Route
          path="restaurant"
          element={
            <RequireAuth
              requiredPermissions={[
                'restaurant:list:',
                'restaurant:create:',
                'restaurant:update:'
              ]}>
              <Restaurant />
            </RequireAuth>
          }
        />
        <Route path="marster" element={<Marster />} />
        <Route
          path="mars-life"
          element={
            <RequireAuth>
              <MarsLife />
            </RequireAuth>
          }
        />
        <Route path="collections" element={<Collections />} />
        <Route path="academy" element={<Academy />} />
        <Route path="join-us" element={<JoinUs />} />

        <Route path="*" element={<NoMatch />} />
      </Route>
    </Routes>
  )
}

function NoMatch() {
  return (
    <div>
      <h2>Nothing to see here!</h2>
      <p>
        <Link to="/">Go to the home page</Link>
      </p>
    </div>
  )
}

import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import Box from '@mui/material/Box'
import IconButton from '@mui/material/IconButton'
import MenuIcon from '@mui/icons-material/Menu'
import {
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  SwipeableDrawer,
  Typography
} from '@mui/material'
import { ToolbarXsProps } from './types'
import AppTitle from './AppTitle'
import AccountMenu from './AccountMenu'
import { PageInfo } from './types'

export default function ToolbarXs({
  appTitle,
  managerModeEnabled,
  userPageInfo,
  managerPageInfo
}: ToolbarXsProps): JSX.Element {
  const [drawerOpen, setDrawerOpen] = useState<boolean>(false)

  const drawerWidth = 250

  const navigate = useNavigate()

  const pagesToListItemButtons = (pages: PageInfo[]) =>
    pages.map(({ icon: Icon, title, path }) => (
      <ListItemButton
        key={title}
        onClick={() => {
          setDrawerOpen(false)
          navigate(path)
        }}>
        <ListItemIcon>
          <Icon />
        </ListItemIcon>
        <ListItemText primary={title} />
      </ListItemButton>
    ))

  const userPageListItemButtons = pagesToListItemButtons(userPageInfo)
  const managerPageListItemButtons = pagesToListItemButtons(managerPageInfo)

  const Drawer = (
    <SwipeableDrawer
      anchor="left"
      open={drawerOpen}
      onClose={() => {
        setDrawerOpen(false)
      }}
      onOpen={() => {
        setDrawerOpen(true)
      }}>
      <Box sx={{ width: drawerWidth }} role="presentation">
        <List>
          {managerModeEnabled && (
            <Typography
              variant="subtitle2"
              sx={{ mx: '17px', mt: '17px', mb: '3px' }}>
              使用者頁面
            </Typography>
          )}
          {userPageListItemButtons}
          {managerModeEnabled && (
            <Typography
              variant="subtitle2"
              sx={{ mx: '17px', mt: '17px', mb: '3px' }}>
              後台管理
            </Typography>
          )}

          {managerPageListItemButtons}
        </List>
      </Box>
    </SwipeableDrawer>
  )

  const MenuButton = (
    <IconButton
      size="large"
      aria-label="account of current user"
      aria-controls="menu-appbar"
      aria-haspopup="true"
      onClick={() => {
        setDrawerOpen(true)
      }}
      color="primary">
      <MenuIcon />
    </IconButton>
  )

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          flexGrow: 1,
          alignItems: 'center',
          justifyContent: 'space-between'
        }}>
        <Box sx={{ flex: '1 1 0', display: 'flex' }}>{MenuButton}</Box>
        <Box sx={{ flexGrow: 0 }}>
          <AppTitle text={appTitle} />
        </Box>
        <Box
          sx={{ flex: '1 1 0', display: 'flex', justifyContent: 'flex-end' }}>
          <AccountMenu />
        </Box>
      </Box>
      {Drawer}
    </>
  )
}

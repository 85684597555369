import { ScopeId } from '@/context/type'

export type Country = {
  isoCode: string
  name: string
}

export type Currency = {
  isoCode: string
  name: string
}

export type Restaurant = {
  id: string
  name: string
  businessAdministrationNumber: string
  detailUrl: string
  country: Country
  city: string
  currency: Currency
  scopes: ScopeId[]
  createdAt: string
  updatedAt: string
}

export type FormMode = 'CREATE' | 'EDIT'

// TODO: get from backend server
interface CountryType {
  code: string
  label: string
  suggested?: boolean
}

export const countries: readonly CountryType[] = [
  { code: 'AX', label: 'Åland Islands', suggested: true },
  { code: 'AD', label: 'Andorra', suggested: true },
  { code: 'AU', label: 'Australia', suggested: true },
  { code: 'AT', label: 'Austria', suggested: true },
  { code: 'BE', label: 'Belgium', suggested: true },
  { code: 'CA', label: 'Canada', suggested: true },
  { code: 'CN', label: 'China', suggested: true },
  { code: 'CX', label: 'Christmas Island', suggested: true },
  { code: 'CK', label: 'Cook Islands', suggested: true },
  { code: 'HR', label: 'Croatia', suggested: true },
  { code: 'CY', label: 'Cyprus', suggested: true },
  { code: 'EE', label: 'Estonia', suggested: true },
  { code: 'FI', label: 'Finland', suggested: true },
  { code: 'FR', label: 'France', suggested: true },
  { code: 'DE', label: 'Germany', suggested: true },
  { code: 'GR', label: 'Greece', suggested: true },
  { code: 'GP', label: 'Guadeloupe', suggested: true },
  { code: 'ID', label: 'Indonesia', suggested: true },
  { code: 'IE', label: 'Ireland', suggested: true },
  { code: 'IT', label: 'Italy', suggested: true },
  { code: 'JP', label: 'Japan', suggested: true },
  { code: 'KI', label: 'Kiribati', suggested: true },
  { code: 'KR', label: 'Korea', suggested: true },
  { code: 'XK', label: 'Kosovo', suggested: true },
  { code: 'LV', label: 'Latvia', suggested: true },
  { code: 'LS', label: 'Lesotho', suggested: true },
  { code: 'LI', label: 'Liechtenstein', suggested: true },
  { code: 'LT', label: 'Lithuania', suggested: true },
  { code: 'LU', label: 'Luxembourg', suggested: true },
  { code: 'MY', label: 'Malaysia', suggested: true },
  { code: 'MC', label: 'Monaco', suggested: true },
  { code: 'ME', label: 'Montenegro', suggested: true },
  { code: 'NA', label: 'Namibia', suggested: true },
  { code: 'NR', label: 'Nauru', suggested: true },
  { code: 'NL', label: 'Netherlands', suggested: true },
  { code: 'NZ', label: 'New Zealand', suggested: true },
  { code: 'PH', label: 'Philippines', suggested: true },
  { code: 'PT', label: 'Portugal', suggested: true },
  { code: 'MF', label: 'Saint Martin', suggested: true },
  { code: 'SM', label: 'San Marino', suggested: true },
  { code: 'SG', label: 'Singapore', suggested: true },
  { code: 'SK', label: 'Slovakia', suggested: true },
  { code: 'SI', label: 'Slovenia', suggested: true },
  { code: 'ZA', label: 'South Africa', suggested: true },
  { code: 'ES', label: 'Spain', suggested: true },
  { code: 'SZ', label: 'Swaziland', suggested: true },
  { code: 'SE', label: 'Sweden', suggested: true },
  { code: 'CH', label: 'Switzerland', suggested: true },
  { code: 'TW', label: 'Taiwan', suggested: true },
  { code: 'TH', label: 'Thailand', suggested: true },
  { code: 'TV', label: 'Tuvalu', suggested: true },
  { code: 'GB', label: 'United Kingdom', suggested: true },
  { code: 'US', label: 'United States', suggested: true },
  { code: 'VN', label: 'Vietnam', suggested: true }
]

interface CurrencyType {
  code: string
  label: string
  suggested?: boolean
}

export const currencies: readonly CurrencyType[] = [
  { code: 'AUD', label: 'Australian dollar', suggested: true },
  { code: 'CAD', label: 'Canadian dollar', suggested: true },
  { code: 'EUR', label: 'Euro', suggested: true },
  { code: 'HKD', label: 'Hong Kong dollar', suggested: true },
  { code: 'IDR', label: 'Indonesian rupiah', suggested: true },
  { code: 'JPY', label: 'Japanese yen', suggested: true },
  { code: 'MYR', label: 'Malaysian ringgit', suggested: true },
  { code: 'TWD', label: 'New Taiwan dollar', suggested: true },
  { code: 'NZD', label: 'New Zealand dollar', suggested: true },
  { code: 'PHP', label: 'Philippine peso', suggested: true },
  { code: 'GBP', label: 'Pound Sterling', suggested: true },
  { code: 'CNY', label: 'Renminbi', suggested: true },
  { code: 'SGD', label: 'Singapore dollar', suggested: true },
  { code: 'ZAR', label: 'South African rand', suggested: true },
  { code: 'KRW', label: 'South Korean won', suggested: true },
  { code: 'SEK', label: 'Swedish krona', suggested: true },
  { code: 'CHF', label: 'Swiss franc', suggested: true },
  { code: 'THB', label: 'Thai baht', suggested: true },
  { code: 'USD', label: 'United States dollar', suggested: true },
  { code: 'VND', label: 'Vietnamese đồng', suggested: true }
]

import { useEffect, useContext } from 'react'
import useApiClient from '@/hook/ApiClient'
import Card from '@mui/material/Card'
import Modal from '@mui/material/Modal'
import { Typography, Box, Button } from '@mui/material'
import {
  FormContainer,
  TextFieldElement,
  AutocompleteElement,
  CheckboxButtonGroup
} from 'react-hook-form-mui'
import { useForm, useWatch } from 'react-hook-form'
import { Restaurant, countries, currencies } from '../types'
import { ScopeId } from '@/context/type'
import RestaurantFormContext from '@/restaurant/context/RestaurantFormContext'
import AuthContext from '@/context/AuthContext'
import SnackbarAlertContext from '@/context/SnackbarAlertContext'
import { checkPermissions } from '@/helper'
import LoadingIndicator from '@/component/LoadingIndicator'

const style = {
  width: { xs: '94%', sm: '80%', md: '60%' },
  display: 'flex',
  overflow: 'scroll',
  flexDirection: 'column',
  alignItems: 'center',
  marginLeft: 'auto',
  marginRight: 'auto',
  py: '30px'
}

export type Props = {
  country: string
}

export type FormValues = {
  name: string
  businessAdministrationNumber: string | null
  detailUrl: string
  countryIsoCode: string
  city: string
  currencyIsoCode: string
  scopes: ScopeId[]
}

export default function RestaurantFormModal({ country }: Props) {
  const { user } = useContext(AuthContext)
  const { showSnackbarAlert } = useContext(SnackbarAlertContext)
  const { formOpened, formMode, restaurant, closeForm, setRestaurants } =
    useContext(RestaurantFormContext)
  const [
    {
      data: restaurantSavingResponse,
      loading: loadingRestaurant,
      error: restaurantRetrievingError
    },
    execute
  ] = useApiClient<{ restaurant: Restaurant }>(
    `/restaurant-review/restaurants${
      formMode === 'CREATE' ? '' : `/${restaurant?.id}`
    }`
  )
  const handleSubmit = async () => {
    const data = formContext.getValues()
    if (selectedCountryCode !== 'TW') data.businessAdministrationNumber = null
    execute({
      method: `${formMode === 'CREATE' ? 'post' : 'put'}`,
      data
    })
  }
  useEffect(() => {
    if (restaurantRetrievingError) {
      console.log(restaurantRetrievingError)
      showSnackbarAlert({
        content: formMode === 'CREATE' ? '新增失敗' : '更新失敗',
        severity: 'error'
      })
      return
    }
    if (loadingRestaurant || !restaurantSavingResponse) return
    if (formMode === 'EDIT') {
      setRestaurants(restaurants =>
        restaurants.map(restaurant =>
          restaurant.id === restaurantSavingResponse.restaurant.id
            ? restaurantSavingResponse.restaurant
            : restaurant
        )
      )
      showSnackbarAlert({
        content: '餐廳資料修改成功！',
        severity: 'success'
      })
      closeForm()
    } else if (formMode === 'CREATE') {
      if (country === restaurantSavingResponse.restaurant.country.isoCode) {
        setRestaurants(restaurants => {
          return [restaurantSavingResponse.restaurant, ...restaurants]
        })
      }
      showSnackbarAlert({
        content: '餐廳資料新增成功！',
        severity: 'success'
      })
      closeForm()
    }
  }, [restaurantSavingResponse, loadingRestaurant, restaurantRetrievingError])

  const defaultValues: Partial<FormValues> = {
    name: restaurant?.name,
    businessAdministrationNumber: restaurant?.businessAdministrationNumber,
    detailUrl: restaurant?.detailUrl,
    countryIsoCode: restaurant?.country.isoCode ?? 'TW',
    city: restaurant?.city,
    currencyIsoCode: restaurant?.currency.isoCode ?? 'TWD',
    scopes: restaurant?.scopes ?? []
  }
  const formContext = useForm()
  const selectedCountryCode = useWatch({
    control: formContext.control,
    name: 'countryIsoCode'
  })
  useEffect(() => {
    if (formOpened) formContext.reset(defaultValues)
  }, [formOpened])

  return (
    <Modal
      open={formOpened}
      onClose={closeForm}
      sx={{
        overflow: 'scroll',
        margin: 1,
        display: 'flex'
      }}>
      <Card sx={{ ...style }}>
        <LoadingIndicator open={loadingRestaurant}>
          <Typography variant="h6" component="div">
            資料處理中...
          </Typography>
        </LoadingIndicator>

        <FormContainer onSuccess={handleSubmit}>
          <Box sx={{ px: '20px' }}>
            <Typography variant="h5" sx={{ textAlign: 'center' }}>
              {formMode === 'CREATE' ? '新增餐廳資料' : '修改餐廳資料'}
            </Typography>
            <Box sx={{ py: '10px' }}>
              <Typography variant="h6">基本資料</Typography>
              <TextFieldElement
                name={'name'}
                label={'餐廳名稱'}
                margin={'dense'}
                control={formContext.control}
                required
                fullWidth
              />
              <AutocompleteElement
                label="國家"
                name="countryIsoCode"
                options={countries.map(c => {
                  return { id: c.code, label: c.code + ' (' + c.label + ')' }
                })}
                required
                control={formContext.control}
                textFieldProps={{
                  margin: 'dense',
                  placeholder: 'TW'
                }}
              />
              <TextFieldElement
                name={'city'}
                label={'城市'}
                margin={'dense'}
                control={formContext.control}
                required
                fullWidth
                placeholder="台北市"
              />
              <TextFieldElement
                name={'detailUrl'}
                label={'Google Maps 連結'}
                margin={'dense'}
                control={formContext.control}
                required
                fullWidth
              />
              <TextFieldElement
                name={'businessAdministrationNumber'}
                label={'統一編號'}
                margin={'dense'}
                control={formContext.control}
                required={selectedCountryCode === 'TW'}
                disabled={selectedCountryCode !== 'TW'}
                fullWidth
                placeholder="12345678"
              />
              <AutocompleteElement
                label="幣別"
                name="currencyIsoCode"
                options={currencies.map(c => {
                  return { id: c.code, label: c.code + ' (' + c.label + ')' }
                })}
                control={formContext.control}
                required
                textFieldProps={{
                  margin: 'dense',
                  placeholder: 'TWD'
                }}
              />
            </Box>
            {checkPermissions(['restaurant:update:*'], user) && (
              <Box sx={{ py: '10px' }}>
                <Typography variant="h6">權限設定</Typography>
                <CheckboxButtonGroup
                  name="scopes"
                  control={formContext.control}
                  options={[
                    {
                      id: 'staff',
                      label: '公司人員'
                    },
                    {
                      id: 'restaurant-review-user',
                      label: '美食評鑑員'
                    },
                    {
                      id: 'level0-member',
                      label: '白板會員'
                    },
                    {
                      id: 'level1-member',
                      label: '土會員'
                    },
                    {
                      id: 'level2-member',
                      label: '水會員'
                    },
                    {
                      id: 'level3-member',
                      label: '火會員'
                    },
                    {
                      id: 'level4-member',
                      label: '風會員'
                    }
                  ]}
                  row
                />
              </Box>
            )}
            <Box
              sx={{
                display: 'flex',
                width: '100%',
                flexWrap: 'nowrap',
                gap: '15px',
                '>*': {
                  flex: '1 1 300px'
                }
              }}>
              <Button variant="contained" type={'submit'} color={'primary'}>
                {formMode === 'CREATE' ? '新增' : '修改'}
              </Button>
              <Button variant="contained" color={'primary'} onClick={closeForm}>
                取消
              </Button>
            </Box>
          </Box>
        </FormContainer>
      </Card>
    </Modal>
  )
}

import LoadingIndicator from '@/component/LoadingIndicator'
import { downloadFileFromBuffer, downloadFileFromUri } from '@/helper'
import useApiClient from '@/hook/ApiClient'
import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography
} from '@mui/material'
import moment from 'moment'
import { useEffect, useState } from 'react'
import useRestaurantReviewReport from '../hook/RestaurantReviewReport'
import RestaurantReviewReportTable from '../component/RestaurantReviewReportTable'
import { RestaurantReviewReportRecord } from '../types'

export default function Console() {
  const [period, setPeriod] = useState(moment().format('YYYY-MM'))
  const [reportShowing, setReportShowing] = useState<boolean>(false)
  const [reportDownloading, setReportDownloading] = useState<boolean>(false)

  const {
    getReport,
    report,
    excelReportFileBuffer,
    retrievingReport,
    reportRetrievingError
  } = useRestaurantReviewReport(period)

  const [
    {
      data: reportImagesUrlRetrievingResponse,
      loading: retrievingReportImagesUrl,
      error: reportImagesUrlRetrievingError
    },
    getReportImagesUrl
  ] = useApiClient<{ url: string }>('/restaurant-review/report-images-url')

  const showReport = () => {
    setReportShowing(true)
    getReport()
  }

  const downloadReport = () => {
    setReportDownloading(true)
    getReport()
  }

  const downloadImages = () => {
    getReportImagesUrl({
      method: 'get',
      params: {
        period
      }
    })
  }

  useEffect(() => {
    setReportShowing(false)
  }, [period])

  useEffect(() => {
    if (
      !reportDownloading ||
      retrievingReport ||
      !excelReportFileBuffer ||
      reportRetrievingError
    )
      return

    downloadFileFromBuffer(
      excelReportFileBuffer,
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      `餐飲評鑑報表-${period}.xlsx`
    )
    setReportDownloading(false)
  }, [excelReportFileBuffer])

  useEffect(() => {
    if (
      retrievingReportImagesUrl ||
      !reportImagesUrlRetrievingResponse ||
      reportImagesUrlRetrievingError
    )
      return

    downloadFileFromUri(reportImagesUrlRetrievingResponse.url)
  }, [
    reportImagesUrlRetrievingResponse,
    retrievingReportImagesUrl,
    reportImagesUrlRetrievingError
  ])

  // generate menu items for period select
  // begin from 2022-03 to current month
  const periodMenuItems = Array.from(
    Array(moment().diff(moment('2022-03'), 'months') + 1).keys()
  )
    .map(i => {
      const v = moment('2022-03').add(i, 'months').format('YYYY-MM')
      return (
        <MenuItem key={v} value={v}>
          {v}
        </MenuItem>
      )
    })
    .reverse()

  return (
    <div>
      <h2>餐飲評鑑報表</h2>

      <LoadingIndicator open={retrievingReportImagesUrl || retrievingReport}>
        <Typography variant="h6" component="div">
          資料處理中...
        </Typography>
      </LoadingIndicator>

      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          padding: '10%',
          gap: '10px'
        }}>
        <FormControl fullWidth>
          <InputLabel id="report-period-select-label">選擇月份</InputLabel>
          <Select
            labelId="report-period-select-label"
            id="report-period-select"
            value={period}
            label="選擇月份"
            onChange={v => {
              setPeriod(v.target.value)
            }}>
            {periodMenuItems}
          </Select>
        </FormControl>
        <Button
          variant="contained"
          onClick={() => {
            showReport()
          }}>
          螢幕顯示評鑑資料
        </Button>
        <Button
          variant="contained"
          onClick={() => {
            downloadReport()
          }}>
          下載評鑑報表
        </Button>
        <Button
          variant="contained"
          onClick={() => {
            downloadImages()
          }}>
          下載評鑑照片
        </Button>
      </Box>

      {reportShowing &&
        report &&
        Array.from(
          report
            .reduce((acc, review) => {
              const user = review.createdBy
              const userReviews = acc.get(user.id) ?? []
              userReviews.push(review)
              acc.set(user.id, userReviews)
              return acc
            }, new Map<string, RestaurantReviewReportRecord[]>())
            .entries(),
          ([userId, userReviews]) => (
            <RestaurantReviewReportTable
              key={userId}
              user={userReviews[0].createdBy}
              userReviews={userReviews}
            />
          )
        )}
    </div>
  )
}

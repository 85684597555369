import { Box } from '@mui/material'

import axios from 'axios'
import { useEffect, useState } from 'react'
import ReactMarkdown from 'react-markdown'

export default function Privacy() {
  const [privacyText, setPrivacyText] = useState('')

  useEffect(() => {
    axios
      .get('https://storage.googleapis.com/membership-public/privacy.md', {
        headers: {
          Accept: 'application/json, text/plain, text/markdown, *.*'
        }
      })
      .then(res => {
        setPrivacyText(res.data)
      })
  }, [])

  return (
    <Box style={{ margin: 30, textAlign: 'left' }}>
      <ReactMarkdown children={privacyText} />
    </Box>
  )
}

import { useEffect } from 'react'
import Container from '@mui/material/Container'
import Grid from '@mui/material/Grid'
import { Typography } from '@mui/material'
import RestaurantListItem from './RestaurantListItem'
import { Restaurant } from '../types'
import useApiClient from '@/hook/ApiClient'
import CircularProgress from '@mui/material/CircularProgress'
import { Box } from '@mui/material'
import RestaurantFormContext from '@/restaurant/context/RestaurantFormContext'
import { useContext } from 'react'

export type Props = {
  country: string
}

export default function RestaurantList({ country }: Props) {
  const { restaurants, setRestaurants } = useContext(RestaurantFormContext)
  const [
    {
      data: restaurantsResponse,
      loading: loadingRestaurants,
      error: getRestaurantsError
    },
    getRestaurants
  ] = useApiClient<{ restaurants: Restaurant[] }>(
    '/restaurant-review/restaurants'
  )

  useEffect(() => {
    getRestaurants({
      method: 'get',
      params: {
        country: country
      }
    })
  }, [country])

  useEffect(() => {
    setRestaurants(r => restaurantsResponse?.restaurants ?? r)
  }, [restaurantsResponse])

  if (loadingRestaurants) {
    return (
      <Box sx={{ padding: '10px' }}>
        <CircularProgress size={25} />
      </Box>
    )
  }

  if (getRestaurantsError) {
    return (
      <Box sx={{ padding: '10px' }}>
        <Typography component="h2" variant="h5">
          ERROR!!
        </Typography>
      </Box>
    )
  }

  return (
    <Container sx={{ px: '10%' }}>
      <Typography component="h2" variant="h6" sx={{ bgcolor: '#eee', py: 2 }}>
        餐廳列表
      </Typography>
      <Grid container>
        {restaurants.map(r => (
          <RestaurantListItem key={r.id} restaurant={r} />
        ))}
      </Grid>
    </Container>
  )
}

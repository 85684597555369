import { useState } from 'react'
import Box from '@mui/material/Box'
import Avatar from '@mui/material/Avatar'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import ListItemIcon from '@mui/material/ListItemIcon'
import Divider from '@mui/material/Divider'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import Tooltip from '@mui/material/Tooltip'
import Settings from '@mui/icons-material/Settings'
import Logout from '@mui/icons-material/Logout'
import { useTheme } from '@mui/material'
import LoginIcon from '@mui/icons-material/Login'
import { Button, CircularProgress, useMediaQuery } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import AuthContext from '@/context/AuthContext'
import { useContext } from 'react'
import UserAvatar from './UserAvatar'

export default function AccountMenu() {
  const theme = useTheme()
  const navigate = useNavigate()
  const screenWidthGreaterThanMd = useMediaQuery(theme.breakpoints.up('md'))
  const { user, signOut, loading } = useContext(AuthContext)
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

  const maxWidth = 360
  const open = Boolean(anchorEl)

  const handleIconClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleMenuClose = () => {
    setAnchorEl(null)
  }

  const DropdownMenu = (
    <Menu
      anchorEl={anchorEl}
      open={open}
      onClose={handleMenuClose}
      onClick={handleMenuClose}
      PaperProps={{
        elevation: 0,
        sx: {
          overflow: 'visible',
          filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
          mt: 2.5,
          width: `min(${maxWidth}px, 100%)`
        }
      }}
      transformOrigin={{ horizontal: 'right', vertical: 'top' }}
      anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          my: 2
        }}>
        <UserAvatar
          size={72}
          user={user}
          color={theme.palette.common.white}
          backgroundColor={theme.palette.common.white}
        />
        <Typography variant="subtitle1" component="div">
          {user?.name ?? '(姓名未提供)'}
        </Typography>
        <Typography
          variant="subtitle2"
          component="div"
          sx={{ color: theme.palette.text.secondary }}>
          {user?.email}
        </Typography>
      </Box>
      <Box
        sx={{
          '& .MuiAvatar-root': {
            width: 24,
            height: 24,
            ml: -0.2,
            mr: 1.6
          }
        }}>
        <MenuItem>
          <Avatar /> 個人資料（尚未開放）
        </MenuItem>
      </Box>
      <Divider />
      <MenuItem>
        <ListItemIcon>
          <Settings fontSize="small" />
        </ListItemIcon>
        設定（尚未開放）
      </MenuItem>
      <MenuItem onClick={signOut}>
        <ListItemIcon>
          <Logout fontSize="small" />
        </ListItemIcon>
        登出
      </MenuItem>
    </Menu>
  )

  const UserIcon = (
    <IconButton sx={{ p: 0 }} onClick={handleIconClick}>
      <UserAvatar
        size={40}
        user={user}
        color={theme.palette.common.white}
        backgroundColor={theme.palette.common.white}
      />
    </IconButton>
  )

  const SignInButton = (
    <Button
      variant="outlined"
      color="secondary"
      startIcon={screenWidthGreaterThanMd ? <LoginIcon /> : null}
      disabled={loading}
      onClick={() => {
        navigate('/sign-in')
      }}>
      登入
    </Button>
  )

  return (
    <>
      <Tooltip title="Open settings">
        {loading ? (
          <CircularProgress color="secondary" size={16} />
        ) : user ? (
          UserIcon
        ) : (
          SignInButton
        )}
      </Tooltip>
      {DropdownMenu}
    </>
  )
}

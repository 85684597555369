import { useState } from 'react'
import { RestaurantReviewReportRecord } from '../types'
import moment from 'moment-timezone'
import Container from '@mui/material/Container'
import Modal from '@mui/material/Modal'
import { Button, Typography } from '@mui/material'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'
import Card from '@mui/material/Card'
import CardMedia from '@mui/material/CardMedia'
import CardActionArea from '@mui/material/CardActionArea'
import ImageIcon from '@mui/icons-material/Image'

export default function (props: {
  user: {
    id: string
    name?: string
  }
  userReviews: RestaurantReviewReportRecord[]
}) {
  const [open, setOpen] = useState<boolean>(false)
  const [picURL, setPicURL] = useState<string>('')
  const showPhoto = (dataUrl: string) => {
    setPicURL(dataUrl)
    setOpen(true)
  }
  const closePhoto = () => setOpen(false)

  const formatTime = (date: string) =>
    moment(date).tz('UTC').format('YYYY/MM/DD HH:mm:ss')
  const removeTrailingZeros = (number: string) => {
    const [integer, decimal] = number.split('.')
    const decimalWithoutTrailingZeros = decimal.replace(/0+$/, '')
    return decimalWithoutTrailingZeros
      ? `${integer}.${decimalWithoutTrailingZeros}`
      : integer
  }

  const totalPriceSum = props.userReviews.reduce(
    (sum, { totalPriceInNtd }) => sum + parseInt(totalPriceInNtd),
    0
  )
  const reimbursedAmountSum = props.userReviews.reduce(
    (sum, { reimbursedAmountInNtd }) => sum + parseInt(reimbursedAmountInNtd),
    0
  )

  return (
    <Container sx={{ my: 6 }}>
      <Typography component="h2" variant="h5" sx={{ bgcolor: '#eee', py: 2 }}>
        {props.user.name ?? '(未填寫姓名)'} ({props.user.id})
      </Typography>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650, whiteSpace: 'pre' }} size="small">
          <TableHead>
            <TableRow>
              <TableCell>餐廳名稱</TableCell>
              <TableCell align="center">用餐照片</TableCell>
              <TableCell align="center">發票照片</TableCell>
              <TableCell align="left">
                用餐時間
                <br />
                (UTC)
              </TableCell>
              <TableCell align="left">交易使用幣別</TableCell>
              <TableCell align="left">
                交易金額
                <br />
                (原始幣別)
              </TableCell>
              <TableCell align="left">匯率</TableCell>
              <TableCell align="left">
                交易金額
                <br />
                (新台幣)
              </TableCell>
              <TableCell align="left">
                補助金額
                <br />
                (新台幣)
              </TableCell>
              <TableCell align="left">發票字軌</TableCell>
              <TableCell align="left">
                補助撥款時間
                <br />
                (UTC)
              </TableCell>
              <TableCell align="left">用餐人數</TableCell>
              <TableCell align="left">餐廳統編</TableCell>
              <TableCell align="left">國家</TableCell>
              <TableCell align="left">
                評鑑建立時間
                <br />
                (UTC)
              </TableCell>
              <TableCell align="left">
                評鑑更新時間
                <br />
                (UTC)
              </TableCell>
              <TableCell align="left">評鑑 ID</TableCell>
              <TableCell align="left">餐廳 ID</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {props.userReviews.map(row => (
              <TableRow
                key={row.id}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                <TableCell component="th" scope="row">
                  {row.restaurant.name}
                </TableCell>
                <TableCell align="center">
                  <Button
                    variant="outlined"
                    onClick={() => showPhoto(row.diningImageUrl)}
                    startIcon={<ImageIcon />}>
                    顯示
                  </Button>
                </TableCell>
                <TableCell align="center">
                  <Button
                    variant="outlined"
                    onClick={() => showPhoto(row.receiptImageUrl)}
                    startIcon={<ImageIcon />}>
                    顯示
                  </Button>
                </TableCell>
                <TableCell align="left">{formatTime(row.visitedAt)}</TableCell>
                <TableCell align="left">{row.currency}</TableCell>
                <TableCell align="right">
                  {removeTrailingZeros(row.totalPrice)}
                </TableCell>
                <TableCell align="right">
                  {removeTrailingZeros(row.exchangeRate)}
                </TableCell>
                <TableCell align="right">{row.totalPriceInNtd}</TableCell>
                <TableCell align="right">{row.reimbursedAmountInNtd}</TableCell>
                <TableCell align="left">{row.invoiceNumber}</TableCell>
                <TableCell align="left">
                  {row.reimbursedAt ? formatTime(row.reimbursedAt) : '尚未撥款'}
                </TableCell>
                <TableCell align="right">{row.peopleAmount}</TableCell>
                <TableCell align="left">
                  {row.restaurant.businessAdministrationNumber}
                </TableCell>
                <TableCell align="left">
                  {row.restaurant.country.name}
                </TableCell>
                <TableCell align="left">{formatTime(row.createdAt)}</TableCell>
                <TableCell align="left">{formatTime(row.updatedAt)}</TableCell>
                <TableCell align="left">{row.id}</TableCell>
                <TableCell align="left">{row.restaurant.id}</TableCell>
              </TableRow>
            ))}
            <TableRow>
              <TableCell colSpan={7}>總金額</TableCell>
              <TableCell align="right">{totalPriceSum}</TableCell>
              <TableCell align="right">{reimbursedAmountSum}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
      <Modal
        open={open}
        onClose={closePhoto}
        sx={{ overflow: 'scroll', margin: 1 }}>
        <Card>
          <CardActionArea component="a" onClick={closePhoto}>
            <CardMedia component="img" image={picURL} alt="No photo" />
          </CardActionArea>
        </Card>
      </Modal>
    </Container>
  )
}

import RestaurantReviewList from '../component/RestaurantReviewList'

export default function Membership() {
  return (
    <div>
      <h2>Membership</h2>
      <RestaurantReviewList />
    </div>
  )
}

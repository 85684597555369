import { Box, Avatar } from '@mui/material'
import { User } from '@/context/type'

const UserAvatar = ({
  size,
  user,
  color,
  backgroundColor
}: {
  size: number
  user: User | null
  color: string
  backgroundColor: string
}) => {
  const sx = {
    width: size,
    height: size,
    backgroundColor,
    color,
    fontSize: size * 0.5
  }
  return (
    <Box sx={{ my: 1 }}>
      {user?.photoUrl ? (
        <Avatar sx={sx} alt="User" src={user.photoUrl} />
      ) : (
        <Avatar sx={sx}>{(user?.name ?? '火').charAt(0)}</Avatar>
      )}
    </Box>
  )
}

export default UserAvatar

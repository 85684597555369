import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { useState, forwardRef, ForwardedRef } from 'react'
import { useTheme, useMediaQuery } from '@mui/material'
import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate'

type Props = {
  inputId: string
  title: string
  showingError: boolean
  placeholderImageUrl?: string
  allowEmpty: boolean
  onFileChange: (file?: File) => void
}

export default forwardRef(
  (
    {
      inputId,
      title,
      onFileChange: changeHandler,
      showingError,
      placeholderImageUrl,
      allowEmpty
    }: Props,
    ref: ForwardedRef<HTMLDivElement> | undefined
  ): JSX.Element => {
    const theme = useTheme()

    const [imageUrl, setImageUrl] = useState<string>()

    const screenWidthGreaterThanMd = useMediaQuery(theme.breakpoints.up('md'))
    const noHover = useMediaQuery('(hover: none)')

    const style = {
      height: screenWidthGreaterThanMd ? 500 : 300,
      width: '100%',
      borderRadius: '4px',
      overflow: 'hidden',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'column',
      color: '#FFFFFF'
    }

    return (
      <Box sx={{ width: '100%' }}>
        <input
          accept="image/*"
          id={inputId}
          type="file"
          onChange={event => {
            if (event.target.files && event.target.files.length > 0) {
              const url = URL.createObjectURL(event.target.files[0])
              setImageUrl(url)
              changeHandler(event.target.files[0])
            }
          }}
          style={{ display: 'none' }}
        />
        <label htmlFor={inputId}>
          <Box
            ref={ref}
            sx={{
              ...style,
              position: 'relative',
              border: `1px solid ${
                showingError && !imageUrl && !allowEmpty
                  ? theme.palette.error.main
                  : 'rgba(0, 0, 0, 0.23)'
              }`,
              '&:hover': noHover
                ? {}
                : {
                    cursor: 'pointer',
                    border: '1px solid rgba(0, 0, 0, 0.87)'
                  }
            }}>
            <Typography
              variant="subtitle2"
              component="div"
              sx={{
                position: 'absolute',
                bottom: 8,
                right: 15,
                zIndex: 1,
                color: theme.palette.grey[600],
                textShadow:
                  '-1px 0 white, 0 1px white, 1px 0 white, 0 -1px white'
              }}>
              {title}
            </Typography>
            <Box
              sx={{
                ...style,
                position: 'absolute',
                zIndex: 2,
                opacity: 0,
                '&:hover': noHover
                  ? {}
                  : {
                      opacity: 1,
                      backgroundColor: 'rgba(0, 0, 0, 0.87)'
                    }
              }}>
              <AddPhotoAlternateIcon sx={{ fontSize: 40 }} />
              選擇照片
            </Box>
            <Box
              sx={{
                ...style,
                position: 'absolute'
              }}>
              {imageUrl ? (
                <img
                  src={imageUrl}
                  style={{
                    maxHeight: '100%',
                    maxWidth: '100%'
                  }}
                />
              ) : placeholderImageUrl ? (
                <img
                  src={placeholderImageUrl}
                  style={{
                    maxHeight: '100%',
                    maxWidth: '100%'
                  }}
                />
              ) : (
                <Box
                  sx={{
                    ...style,
                    color: theme.palette.grey[600]
                  }}>
                  <AddPhotoAlternateIcon sx={{ fontSize: 40 }} />
                  選擇照片
                </Box>
              )}
            </Box>
          </Box>
        </label>
      </Box>
    )
  }
)

import AppBar from '@mui/material/AppBar'
import Toolbar from '@mui/material/Toolbar'
import Container from '@mui/material/Container'
import CardMembershipIcon from '@mui/icons-material/CardMembership'
import SchoolIcon from '@mui/icons-material/School'
import PeopleIcon from '@mui/icons-material/People'
import NightlifeIcon from '@mui/icons-material/Nightlife'
import SportsScoreIcon from '@mui/icons-material/SportsScore'
import CardGiftcardIcon from '@mui/icons-material/CardGiftcard'
import AssessmentIcon from '@mui/icons-material/Assessment'
import StorefrontIcon from '@mui/icons-material/Storefront'
import { Box, useMediaQuery } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import ToolbarMd from './toolbar/ToolbarMd'
import ToolbarXs from './toolbar/ToolbarXs'
import { Outlet } from 'react-router-dom'
import { common } from '@mui/material/colors'
import { ComponentType, useContext } from 'react'
import { checkPermissions } from '@/helper'
import { PermissionString } from '@/context/type'
import AuthContext from '@/context/AuthContext'

type Page = {
  title: string
  icon: ComponentType
  path: string
  permissions: PermissionString[]
}

const userPages: Page[] = [
  {
    title: '會員專區',
    icon: CardMembershipIcon,
    path: '/membership',
    permissions: []
  },
  {
    title: '火星人試驗',
    icon: SportsScoreIcon,
    path: '/marster',
    permissions: []
  },
  {
    title: '火星生活',
    icon: NightlifeIcon,
    path: '/mars-life',
    permissions: []
  },
  {
    title: '商品兌換',
    icon: CardGiftcardIcon,
    path: '/collections',
    permissions: []
  },
  {
    title: '火星學院',
    icon: SchoolIcon,
    path: '/academy',
    permissions: []
  },
  {
    title: '加入我們',
    icon: PeopleIcon,
    path: '/join-us',
    permissions: []
  }
]

const managerPages: Page[] = [
  {
    title: '餐飲評鑑報表',
    icon: AssessmentIcon,
    path: '/restaurant-review-report',
    permissions: ['restaurant-review-finance:get:']
  },
  {
    title: '餐廳管理',
    icon: StorefrontIcon,
    path: '/restaurant',
    permissions: [
      'restaurant:list:',
      'restaurant:create:',
      'restaurant:update:'
    ]
  }
]

export default function Layout() {
  const theme = useTheme()
  const screenWidthGreaterThanMd = useMediaQuery(theme.breakpoints.up('md'))
  const { user } = useContext(AuthContext)

  const authorizedManagerPages: Page[] = managerPages.filter(page =>
    checkPermissions(page.permissions, user)
  )
  const authorizedUserPages: Page[] = userPages.filter(page =>
    checkPermissions(page.permissions, user)
  )
  const managerModeEnabled: boolean = managerPages.some(page =>
    checkPermissions(page.permissions, user)
  )

  const title = ''
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        width: '100%'
      }}>
      <AppBar position="static" sx={{ backgroundColor: common.black }}>
        <Container maxWidth="xl">
          <Toolbar disableGutters>
            {screenWidthGreaterThanMd ? (
              <ToolbarMd
                appTitle={title}
                managerModeEnabled={managerModeEnabled}
                userPageInfo={authorizedUserPages}
                managerPageInfo={authorizedManagerPages}
              />
            ) : (
              <ToolbarXs
                appTitle={title}
                managerModeEnabled={managerModeEnabled}
                userPageInfo={authorizedUserPages}
                managerPageInfo={authorizedManagerPages}
              />
            )}
          </Toolbar>
        </Container>
      </AppBar>

      <Outlet />
    </Box>
  )
}

import { useState } from 'react'
import RestaurantControl from '../component/RestaurantControl'
import RestaurantList from '../component/RestaurantList'
import RestaurantFormModal from '../component/RestaurantFormModal'
import RestaurantFormProvider from '@/restaurant/context/RestaurantFormProvider'

export default function Restaurant() {
  const [country, setCountry] = useState('TW')
  const handleChange = (country: string) => {
    setCountry(country)
  }
  return (
    <RestaurantFormProvider>
      <div>
        <h2>餐廳管理</h2>
        <RestaurantControl country={country} onChangeCountry={handleChange} />
        <RestaurantList country={country} />
        <RestaurantFormModal country={country} />
      </div>
    </RestaurantFormProvider>
  )
}

import {
  GoogleAuthProvider,
  FacebookAuthProvider,
  getAuth,
  signInWithPopup,
  User as FirebaseUser
} from 'firebase/auth'
import { useEffect, useState } from 'react'
import {
  FirebaseSignInProvider,
  SignInApiResponse,
  User,
  UserJwt
} from './type'
import axios from 'axios'
import AuthContext from './AuthContext'
import moment from 'moment'
import decode from 'jwt-decode'
import config from '@/config'

const googleAuthProvider = new GoogleAuthProvider()
googleAuthProvider.setCustomParameters({
  prompt: 'select_account'
})

const facebookAuthProvider = new FacebookAuthProvider()

const providers = {
  Google: googleAuthProvider,
  Facebook: facebookAuthProvider,
  Apple: {
    providerId: 'Apple'
  }
}

const signInWithFirebaseToken = async (
  firebaseUser: FirebaseUser
): Promise<SignInApiResponse> => {
  const firebaseToken = await firebaseUser.getIdToken()
  const path = '/auth/session'
  const { data } = await axios.post<SignInApiResponse>(
    `${config.apiClient.BASE_URL}${path}`,
    {},
    {
      headers: {
        Authorization: `Bearer ${firebaseToken}`
      }
    }
  )
  return data
}

const isTokenValid = (token: string) => {
  try {
    const { exp } = decode<UserJwt>(token)
    return moment(exp.toString(), 'X').isAfter(moment())
  } catch (e) {
    return false
  }
}

const AuthProvider: React.FC<{
  children?: JSX.Element
}> = props => {
  const [firebaseUser, setFirebaseUser] = useState<FirebaseUser | null>()
  const [loading, setLoading] = useState(true)
  const [userToken, setUserToken] = useState<string | null>(null)
  const [user, setUser] = useState<User | null>(null)

  const getUserToken = async () => {
    if (userToken && isTokenValid(userToken)) {
      return userToken
    }

    if (!firebaseUser) {
      console.error('No Firebase user found')
      throw new Error('No Firebase user found')
    }

    try {
      const { token, user } = await signInWithFirebaseToken(firebaseUser)
      setUserToken(token)
      setUser(user)
      return token
    } catch (e) {
      return null
    }
  }

  useEffect(() => {
    async function updateSignInState() {
      // first time
      if (firebaseUser === undefined) return

      try {
        if (firebaseUser) {
          const { token, user } = await signInWithFirebaseToken(firebaseUser)
          setUserToken(token)
          setUser(user)
        } else {
          setUserToken(null)
          setUser(null)
        }
      } catch (e) {
        setUserToken(null)
        setUser(null)
      }

      setLoading(false)
    }

    updateSignInState()
  }, [firebaseUser])

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(u => {
      setLoading(true)
      setFirebaseUser(u)
    })
    return unsubscribe
  }, [])

  const auth = getAuth()

  const signIn = async (providerId: FirebaseSignInProvider) => {
    setLoading(true)
    auth.useDeviceLanguage()
    try {
      await signInWithPopup(auth, providers[providerId])
    } catch (e) {
      setLoading(false)
    }
  }

  const signOut = () => {
    setLoading(true)
    return auth.signOut()
  }

  const context = {
    getUserToken,
    user,
    firebaseUser,
    loading,
    signIn,
    signOut
  }

  return (
    <AuthContext.Provider value={context}>
      {props.children}
    </AuthContext.Provider>
  )
}

export default AuthProvider

import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import Rating from '@mui/material/Rating'
import Typography from '@mui/material/Typography'
import { RatingDimension } from '@/membership/types'
import { styled } from '@mui/material/styles'
import { useTheme } from '@mui/material/styles'
import RatingStar from '@/membership/image/rating-star.png'
import EmptyRatingStar from '@/membership/image/empty-rating-star.png'

export type Props = {
  dimension: RatingDimension
  value: number
  onChange: (value: number) => void
}

export default function RatingInput({
  dimension,
  value,
  onChange: handleChange
}: Props): JSX.Element {
  const theme = useTheme()
  const iconSize = 24

  const StyledRating = styled(Rating)({
    '& .MuiRating-iconFilled': {
      color: theme.palette.primary.main
    },
    '& .MuiRating-iconHover': {
      color: theme.palette.primary.light
    }
  })

  return (
    <Card variant="outlined" sx={{ margin: '10px 0px', width: '100%' }}>
      <CardContent sx={{ textAlign: 'left' }}>
        <Typography variant="h6" component="div">
          {dimension.title}
        </Typography>

        <Typography sx={{ mb: 1.5 }} color="text.secondary">
          {dimension.description}
        </Typography>
        <StyledRating
          name={dimension.id}
          defaultValue={1}
          max={3}
          precision={1}
          value={value}
          icon={<img src={RatingStar} alt="rating-star" width={iconSize} />}
          emptyIcon={
            <img
              src={EmptyRatingStar}
              alt="empty-rating-star"
              width={iconSize}
            />
          }
          onChange={(event, newValue) => {
            handleChange(newValue ?? 0)
          }}
        />
      </CardContent>
    </Card>
  )
}

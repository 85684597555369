import { Snackbar, Alert, SnackbarCloseReason, AlertColor } from '@mui/material'

type Props = {
  children?: JSX.Element | string
  onClose?: (
    event: Event | React.SyntheticEvent<unknown, Event>,
    reason: SnackbarCloseReason
  ) => void
  open: boolean
  autoHideDuration?: number
  severity?: AlertColor
}

const SnackbarAlert: React.FC<Props> = ({
  children,
  onClose,
  open,
  autoHideDuration = 6000,
  severity = 'success'
}) => {
  return (
    <Snackbar open={open} autoHideDuration={autoHideDuration} onClose={onClose}>
      <Alert severity={severity} sx={{ width: '100%' }}>
        {children}
      </Alert>
    </Snackbar>
  )
}

export default SnackbarAlert

import { ForwardedRef, forwardRef } from 'react'
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material'

export type Props = {
  defaultValue: number
  min: number
  max: number
  value?: number
  onChange?: (value: number) => void
}

export default forwardRef(
  (
    {
      defaultValue,
      min = 0,
      max = 10,
      onChange: handleChange,
      value: valueProp
    }: Props,
    ref: ForwardedRef<HTMLInputElement> | undefined
  ): JSX.Element => {
    return (
      <FormControl>
        <InputLabel id="people-amount-select-label">用餐人數</InputLabel>
        <Select
          labelId="people-amount-select-label"
          id="people-amount-select"
          ref={ref}
          defaultValue={defaultValue}
          label="用餐人數"
          inputProps={{
            sx: {
              textAlign: 'left'
            }
          }}
          value={valueProp ?? 2}
          onChange={e => {
            const v =
              typeof e.target.value === 'string'
                ? parseInt(e.target.value)
                : e.target.value
            handleChange?.(v)
          }}>
          {Array.from(Array(max - min + 1).keys()).map(i => (
            <MenuItem key={i} value={i + min}>
              {i + min}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    )
  }
)

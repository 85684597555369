import { Box, Link, Typography } from '@mui/material'
import googleIcon from '../image/google.png'
import facebookIcon from '../image/facebook.png'
import { useContext, useEffect } from 'react'
import SignInButton from '../component/SignInButton'
import { useLocation, useNavigate } from 'react-router-dom'
import AuthContext from '../context/AuthContext'
import config from '@/config'

type From = {
  pathname: string
}

type StateWithFromPathname = {
  from: From
}

// https://github.com/remix-run/react-router/issues/8503#issuecomment-997543520
const isStateWithFromPathname = (
  value: unknown
): value is StateWithFromPathname => {
  function isSomethingLike<T>(
    given: unknown
  ): given is Partial<Record<keyof T, unknown>> {
    return typeof given === 'object' && given !== null
  }

  return (
    isSomethingLike<StateWithFromPathname>(value) &&
    'from' in value &&
    isSomethingLike<From>(value.from) &&
    'pathname' in value.from
  )
}

export default function SignIn() {
  const { firebaseUser, user, loading } = useContext(AuthContext)
  const navigate = useNavigate()
  const { state } = useLocation()

  useEffect(() => {
    const from = isStateWithFromPathname(state) ? state.from.pathname : '/'
    if (loading) return
    if (firebaseUser && user) {
      navigate(from, { replace: true })
    } else if (firebaseUser && !user) {
      navigate('/register')
    }
  }, [firebaseUser, loading])

  return (
    <Box
      sx={{
        display: 'flex',
        height: '100vh',
        width: '100vw',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center'
      }}>
      <Typography variant="h4" component="div" sx={{ mb: '40px' }}>
        火星計畫會員登入
      </Typography>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'stretch'
        }}>
        <SignInButton providerId="Google" iconImgSrc={googleIcon}>
          Sign in with Google
        </SignInButton>
        {config.ALLOW_FACEBOOK_SIGNIN && (
          <SignInButton
            providerId="Facebook"
            iconImgSrc={facebookIcon}
            color="#FFFFFF"
            backgroundColor="#1778F2">
            Sign in with Facebook
          </SignInButton>
        )}
        <Link href="/">Home</Link>
      </Box>
    </Box>
  )
}

import base from './base'
import development from './development'
import local from './local'
import production from './production'
import staging from './staging'
import { Config } from './types'

function getConfig(): Config {
  if (process.env.REACT_APP_ENV === 'local') return local
  if (process.env.REACT_APP_ENV === 'development') return development
  if (process.env.REACT_APP_ENV === 'staging') return staging
  if (process.env.REACT_APP_ENV === 'production') return production
  return base
}

const config: Config = getConfig()
export default config

import { useState } from 'react'
import { PublicRestaurantReview } from '../types'
import RadarPlot from './RadarPlot'
import Typography from '@mui/material/Typography'
import Grid from '@mui/material/Grid'
import Card from '@mui/material/Card'
import CardHeader from '@mui/material/CardHeader'
import CardActionArea from '@mui/material/CardActionArea'
import CardContent from '@mui/material/CardContent'
import Avatar from '@mui/material/Avatar'
import moment from 'moment'
import Modal from '@mui/material/Modal'

export type Props = {
  review: PublicRestaurantReview
}

const style = {
  position: 'absolute',
  width: { xs: '94%', sm: '80%', md: '60%' },
  left: { xs: '3%', sm: '10%', md: '20%' },
  transform: { xs: 'translate(0,3%)', sm: 'translate(0,10%)' }
}

export default function ReviewPreviewArticle({ review }: Props) {
  const [open, setOpen] = useState<boolean>(false)
  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)

  return (
    <>
      <Grid item xs={12} md={6}>
        <Card>
          <CardActionArea
            component="a"
            onClick={handleOpen}
            sx={{ display: 'flex', height: { md: 240 } }}>
            <CardContent sx={{ flex: 1 }}>
              <Typography component="h2" variant="h5">
                {review.restaurant.name}
              </Typography>
              <Typography variant="subtitle1" color="text.secondary">
                {review.createdBy.name + ' at '}
                {review.restaurant.country.name}
                {' - ' + moment(review.visitedAt).format('ll')}
              </Typography>
              <Typography
                variant="subtitle1"
                paragraph
                sx={{ wordBreak: 'break-all' }}>
                {review.comments.substring(0, 50)}
              </Typography>
              <Typography variant="subtitle1" color="primary">
                Continue reading...
              </Typography>
            </CardContent>
            <CardContent
              sx={{ width: 160, display: { xs: 'none', sm: 'block' } }}>
              <RadarPlot review={review} />
            </CardContent>
          </CardActionArea>
        </Card>
      </Grid>
      <Modal
        open={open}
        onClose={handleClose}
        sx={{ overflow: 'scroll', margin: 1 }}>
        <Card sx={{ ...style }}>
          <CardActionArea component="a" onClick={handleClose}>
            <CardHeader
              avatar={<Avatar alt="User" src={review.createdBy.photoUrl} />}
              title={review.createdBy.name}
              subheader={moment(review.visitedAt).format('ll')}
            />
            <CardContent
              sx={{
                position: 'relative',
                width: { xs: '80%', sm: '50%' },
                left: { xs: '10%', sm: '25%' }
              }}>
              <RadarPlot review={review} />
            </CardContent>
            <CardContent>
              <Typography component="h2" variant="h5">
                {review.restaurant.name}
                {' ( '}
                {review.restaurant.country.name}
                {' ) '}
              </Typography>
              <Typography
                variant="subtitle1"
                paragraph
                sx={{ wordBreak: 'break-all' }}>
                {review.comments}
              </Typography>
            </CardContent>
          </CardActionArea>
        </Card>
      </Modal>
    </>
  )
}

import { PublicRestaurantReview } from '../types'
import { Radar } from 'react-chartjs-2'
import { useTheme } from '@mui/material/styles'

export type Props = {
  review: PublicRestaurantReview
}

export default function RadarPlot({ review }: Props) {
  const theme = useTheme()
  const { healthy, aesthetics, environment, specialty, cooking, inspirit } =
    review.ratings

  const data = {
    labels: ['健康', '美學', '獨特', '念力', '細緻', '環境'],
    datasets: [
      {
        data: [healthy, aesthetics, specialty, inspirit, cooking, environment],
        backgroundColor: 'rgba(100, 150, 232, 0.2)',
        borderColor: theme.palette.secondary.main,
        borderWidth: 1
      }
    ]
  }

  const options = {
    layout: {
      autoPadding: false,
      padding: {
        bottom: 10
      }
    },
    scales: {
      r: {
        suggestedMin: 0,
        suggestedMax: 3,
        ticks: {
          display: false,
          stepSize: 1
        }
      }
    },
    plugins: {
      legend: {
        display: false
      }
    },
    elements: {
      line: {
        borderWidth: 1
      }
    }
  }

  return <Radar data={data} options={options} />
}

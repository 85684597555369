import { useNavigate } from 'react-router-dom'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'

export default function AppTitle({ text }: { text: string }) {
  const navigate = useNavigate()
  return (
    <Button
      onClick={() => {
        navigate('/')
      }}>
      <img
        src="logo192.png"
        style={{ maxHeight: '42px', marginRight: '10px' }}
      />
      <Typography variant="h6" noWrap component="div">
        {text}
      </Typography>
    </Button>
  )
}

import { InputAdornment, Typography, useTheme } from '@mui/material'
import TextField from '@mui/material/TextField'
import { ForwardedRef, forwardRef, useState, useEffect } from 'react'

export type Props = {
  showingError: boolean
  onChange?: (value: string, error?: Error) => void
  value?: string
  minimumLength?: number
}

export default forwardRef(
  (
    {
      showingError,
      onChange: changeHandler,
      value: valueProp,
      minimumLength = 100
    }: Props,
    ref: ForwardedRef<HTMLInputElement> | undefined
  ): JSX.Element => {
    const isValid = (v?: string) => v && v.length >= minimumLength

    const errorMessage = `用餐心得請輸入至少 ${minimumLength} 字`
    const defaultError = new Error(errorMessage)

    const [error, setError] = useState<Error | undefined>()
    const [touched, setTouched] = useState(false)
    const [count, setCount] = useState(0)

    const theme = useTheme()

    const [value, setValue] = useState<string>('')

    useEffect(() => {
      if (valueProp === undefined) return

      setValue(valueProp)
    }, [valueProp])

    useEffect(() => {
      const e = isValid(value) ? undefined : defaultError
      setError(e)
      setCount(value.length)
      changeHandler?.(value, e)
    }, [value])

    return (
      <>
        {(touched || showingError) && error && (
          <Typography
            variant="caption"
            color="error"
            sx={{ alignSelf: 'flex-start' }}>
            *{errorMessage}
          </Typography>
        )}
        <TextField
          id="comments"
          ref={ref}
          label={`用餐心得 (${minimumLength}字以上)`}
          InputProps={{
            style: { alignItems: 'flex-end' },
            endAdornment: (
              <InputAdornment
                sx={{
                  color:
                    (touched || showingError) && error
                      ? theme.palette.error.main
                      : theme.palette.text.secondary
                }}
                position="end">
                {count}
              </InputAdornment>
            )
          }}
          multiline
          rows={10}
          fullWidth
          error={(touched || showingError) && !!error}
          value={value}
          onChange={v => {
            setValue(v.target.value)
          }}
          onBlur={() => {
            setTouched(true)
          }}
        />
      </>
    )
  }
)

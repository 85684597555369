import useApiClient from '@/hook/ApiClient'
import { PublicRestaurantReview, RestaurantReview } from '@/membership/types'
import ReviewPreviewArticle from '@/membership/component/ReviewPreviewArticle'
import { useEffect, useContext, useState, useRef } from 'react'
import AuthContext from '@/context/AuthContext'
import { Waypoint } from 'react-waypoint'
import Container from '@mui/material/Container'
import Grid from '@mui/material/Grid'
import CircularProgress from '@mui/material/CircularProgress'
import { createTheme, ThemeProvider } from '@mui/material/styles'
import { Box } from '@mui/material'
import Typography from '@mui/material/Typography'

export default function MarsLife() {
  const theme = createTheme()
  const { user } = useContext(AuthContext)
  const [reviews, setReviews] = useState<
    (RestaurantReview | PublicRestaurantReview)[]
  >([])
  const cursorRef = useRef('')
  const [
    { data: reviewsResponse, loading: loadingReviews, error: getReviewsError },
    getReviews
  ] = useApiClient<{ reviews: PublicRestaurantReview[]; next: string }>(
    '/restaurant-review/reviews'
  )

  useEffect(() => {
    if (!user || reviews.length) return
    getReviews({
      method: 'get',
      params: {
        limit: 10
      }
    })
  }, [user])

  useEffect(() => {
    if (!reviewsResponse) return
    if (reviewsResponse.reviews.length === 0) {
      cursorRef.current = ''
      return
    }
    if (
      reviews.length &&
      reviewsResponse.reviews[reviewsResponse.reviews.length - 1].id ===
        reviews[reviews.length - 1].id
    )
      return
    setReviews(prev => [...prev, ...reviewsResponse.reviews])
    cursorRef.current = reviewsResponse.next
  }, [reviewsResponse])

  if (!reviewsResponse) {
    return (
      <Box>
        <Typography component="h2" variant="h5">
          Loading...
        </Typography>
      </Box>
    )
  }

  if (getReviewsError) {
    return (
      <Box>
        <Typography component="h2" variant="h5">
          Error!
        </Typography>
      </Box>
    )
  }

  return (
    <ThemeProvider theme={theme}>
      <Container maxWidth="lg" sx={{ py: 4 }}>
        <Grid container spacing={4}>
          {reviews.map(r => (
            <ReviewPreviewArticle key={r.id} review={r} />
          ))}
        </Grid>
        {loadingReviews && (
          <Box sx={{ padding: '10px' }}>
            <CircularProgress size={25} />
          </Box>
        )}
      </Container>
      <Waypoint
        key={cursorRef.current}
        onEnter={() => {
          if (cursorRef.current && !loadingReviews) {
            getReviews({
              method: 'get',
              params: {
                limit: 10,
                after: encodeURIComponent(cursorRef.current)
              }
            })
          }
        }}
        bottomOffset={'-10px'}
      />
    </ThemeProvider>
  )
}

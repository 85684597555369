import base from './base'
import { Config } from './types'

const config: Config = {
  ...base,
  ENV: 'development',
  apiClient: {
    ...base.apiClient,
    BASE_URL: 'https://api.dev.projectmars.club/restful/v1'
  }
}

export default config

export type InvoiceQrCodeContent = {
  rawData: string
  invoiceNumber: string
  date: string
  randomCode: string
  salesAmount: number
  totalPrice: number
  buyerGuiNumber: string
  sellerGuiNumber: string
}

export const parseEInvoiceQrCode = (
  data: string
): InvoiceQrCodeContent | null => {
  const regexp = /(.{10})(.{7})(.{4})(.{8})(.{8})(.{8})(.{8})(.{24})/g

  if (!regexp.test(data)) return null

  // reset regexp since it will be used again
  regexp.lastIndex = 0

  const [
    [
      rawData,
      invoiceNumber,
      date,
      randomCode,
      salesAmount,
      totalPrice,
      buyerGuiNumber,
      sellerGuiNumber
    ]
  ] = [...data.matchAll(regexp)]

  if (!rawData) return null

  return {
    rawData,
    invoiceNumber,
    date,
    randomCode,
    salesAmount: parseInt(salesAmount, 16),
    totalPrice: parseInt(totalPrice, 16),
    buyerGuiNumber,
    sellerGuiNumber
  }
}

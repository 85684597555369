import { Navigate, useLocation } from 'react-router-dom'
import { Box, CircularProgress } from '@mui/material'
import { useContext } from 'react'
import AuthContext from '../context/AuthContext'
import { checkPermissions } from '@/helper'
import { PermissionString } from '@/context/type'

export default function RequireAuth({
  children,
  requiredPermissions = []
}: {
  children: JSX.Element
  requiredPermissions?: PermissionString[]
}) {
  const { loading, user } = useContext(AuthContext)
  const location = useLocation()

  if (loading)
    return (
      <Box
        sx={{
          display: 'flex',
          flexGrow: 1,
          justifyContent: 'center',
          alignItems: 'center'
        }}>
        <CircularProgress color="secondary" disableShrink size={24} />
      </Box>
    )

  if (!user) {
    return <Navigate to="/sign-in" state={{ from: location }} replace />
  }

  if (!checkPermissions(requiredPermissions, user)) {
    return <Navigate to="/" replace />
  }

  return children
}

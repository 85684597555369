import { useState } from 'react'
import SnackbarAlertContext from './SnackbarAlertContext'
import SnackbarAlert from '@/component/SnackbarAlert'
import { AlertColor } from '@mui/material'

const SnackbarAlertProvider: React.FC<{
  children?: JSX.Element
}> = props => {
  const [open, setOpen] = useState(false)
  const [message, setMessage] = useState<JSX.Element | string>('')
  const [severity, setSeverity] = useState<AlertColor>('success')
  const [autoHideDuration, setAutoHideDuration] = useState(6000)

  const showSnackbarAlert = (props: {
    content?: JSX.Element | string
    autoHideDuration?: number
    severity?: AlertColor
  }) => {
    setOpen(true)
    setMessage(props.content || '')
    setSeverity(props.severity || 'success')
    setAutoHideDuration(props.autoHideDuration || 6000)
  }

  const contextValue = {
    showSnackbarAlert
  }

  return (
    <SnackbarAlertContext.Provider value={contextValue}>
      {props.children}
      <SnackbarAlert
        open={open}
        onClose={() => setOpen(false)}
        autoHideDuration={autoHideDuration}
        severity={severity}>
        {message}
      </SnackbarAlert>
    </SnackbarAlertContext.Provider>
  )
}

export default SnackbarAlertProvider

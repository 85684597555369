import { Box, Link, Typography } from '@mui/material'

export default function Home() {
  return (
    <Box style={{ margin: 30, textAlign: 'left' }}>
      <Typography variant="h5">系統公告</Typography>
      <Typography variant="subtitle1">
        1. 美食評鑑員請
        <Link
          href="https://docs.google.com/forms/d/e/1FAIpQLSfw5Zjjh0HVG4bbxm9IvfcMzYBwRTnGZTtTD_mF0fx3L5mV3w/viewform"
          target="_blank">
          點此
        </Link>
        填寫報名表單，以利公司進行後續獎勵寄送作業。詳情請參閱
        <Link href="https://projectmars.shop/mars-inspector/" target="_blank">
          火星計畫官方網站
        </Link>
        。
      </Typography>

      <Typography variant="subtitle1">
        2. 歡迎各大專院校學生參加火星人試驗。詳情請參閱
        <Link href="https://projectmars.org/gym-challenge/" target="_blank">
          火星人試驗官方網站
        </Link>
        。
      </Typography>

      <Typography variant="subtitle1">
        3.
        若您在使用我們的應用程式時遇到任何問題或疑問，請不要猶豫，立即通過電子郵件與我們聯繫。我們的客戶服務團隊會盡快回覆您，以提供必要的支援與解答。電子郵件:
        <Link href="mailto://test@projectmars.info" target="_blank">
          test@projectmars.info
        </Link>
        我們期待您的來信，並致力於為您提供最佳的服務體驗。感謝您選擇我們的應用程式！。
      </Typography>
    </Box>
  )
}

import { Typography } from '@mui/material'
import TextField from '@mui/material/TextField'
import moment from 'moment'
import {
  useState,
  useEffect,
  ForwardedRef,
  forwardRef,
  useContext
} from 'react'
import AuthContext from '@/context/AuthContext'
import { checkPermissions } from '@/helper'

export type Props = {
  onChange?: (value: string, error?: Error) => void
  value?: string
  showingError: boolean
}

export default forwardRef(
  (
    { onChange: changeHandler, value: valueProp, showingError }: Props,
    ref: ForwardedRef<HTMLInputElement> | undefined
  ): JSX.Element => {
    const { user } = useContext(AuthContext)
    if (!moment(valueProp).isValid()) {
      console.log('invalid value in VisitedAtSelect', valueProp)
    }

    const isValid = (v: string | null) =>
      v !== null &&
      moment(v).isSameOrBefore(moment()) &&
      (moment(v).isAfter(moment().subtract(14, 'days')) ||
        checkPermissions(['restaurant-review:create:all'], user))

    const errorMessage = checkPermissions(
      ['restaurant-review:create:all'],
      user
    )
      ? '僅允許上傳今天（包含今天）以前的評鑑'
      : '僅允許上傳十四天內的評鑑'
    const defaultError = new Error(errorMessage)

    const [error, setError] = useState<Error>()
    const [touched, setTouched] = useState(false)

    const [value, setValue] = useState<string>(
      valueProp ?? '2022-02-02T00:00:00.000Z'
    )

    useEffect(() => {
      if (valueProp === undefined) return

      setValue(valueProp)
    }, [valueProp])

    useEffect(() => {
      const e = isValid(value) ? undefined : defaultError
      setError(e)
      changeHandler?.(value, e)
    }, [value])

    return (
      <>
        {(touched || showingError) && error && (
          <Typography
            variant="caption"
            color="error"
            sx={{ alignSelf: 'flex-start' }}>
            *{errorMessage}
          </Typography>
        )}
        <TextField
          id="visited-at"
          ref={ref}
          label="用餐時間"
          type="datetime-local"
          error={(touched || showingError) && !!error}
          value={moment(value).format('YYYY-MM-DDTHH:mm')}
          onChange={v => {
            setValue(
              moment(v.target.value)
                .add(Math.floor(Math.random() * 1000 * 60), 'ms') // prevent timestamp conflict
                .toISOString()
            )
          }}
          onBlur={() => {
            setTouched(true)
          }}
        />
      </>
    )
  }
)

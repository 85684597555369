import './App.scss'
import CssBaseline from '@mui/material/CssBaseline'
import AppRoutes from './AppRoutes'
import { createTheme } from '@mui/material/styles'
import { ThemeProvider } from '@mui/material'
import {
  Chart,
  RadialLinearScale,
  PointElement,
  LineElement,
  Filler,
  Tooltip,
  Legend
} from 'chart.js'
import AuthProvider from './context/AuthProvider'
import SnackbarAlertProvider from './context/SnackbarAlertProvider'

Chart.register(
  RadialLinearScale,
  PointElement,
  LineElement,
  Filler,
  Tooltip,
  Legend
)

const theme = createTheme({
  palette: {
    primary: {
      main: '#D69F72'
    },
    secondary: {
      main: '#00E1FF'
    }
  }
})

function App() {
  return (
    <div className="App">
      <CssBaseline />

      <ThemeProvider theme={theme}>
        <AuthProvider>
          <SnackbarAlertProvider>
            <AppRoutes />
          </SnackbarAlertProvider>
        </AuthProvider>
      </ThemeProvider>
    </div>
  )
}

export default App

import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import { ToolbarMdProps } from './types'
import { useNavigate } from 'react-router-dom'
import AppTitle from './AppTitle'
import AccountMenu from './AccountMenu'
import { PageInfo } from './types'
import Divider from '@mui/material/Divider'
import { useTheme } from '@mui/material'

export default function ToolbarMd({
  appTitle,
  managerModeEnabled,
  userPageInfo,
  managerPageInfo
}: ToolbarMdProps): JSX.Element {
  const navigate = useNavigate()
  const theme = useTheme()

  const pagesToLinks = (pages: PageInfo[]) =>
    pages.map(({ title, path }) => (
      <Button
        key={title}
        sx={{ my: 2, display: 'block' }}
        color="primary"
        onClick={() => {
          navigate(path)
        }}>
        {title}
      </Button>
    ))

  const UserPageLinks = pagesToLinks(userPageInfo)
  const ManagerPageLinks = pagesToLinks(managerPageInfo)

  return (
    <Box sx={{ display: 'flex', flexGrow: 1, alignItems: 'center' }}>
      <Box sx={{ flexGrow: 0, mr: 2 }}>
        <AppTitle text={appTitle} />
      </Box>

      <Box sx={{ flexGrow: 1, display: 'flex' }}>
        {UserPageLinks}
        {managerModeEnabled && (
          <Divider
            variant="middle"
            sx={{
              width: '1px',
              my: '20px',
              bgcolor: theme.palette.grey[800]
            }}
          />
        )}
        {ManagerPageLinks}
      </Box>

      <Box sx={{ flexGrow: 0 }}>
        <AccountMenu />
      </Box>
    </Box>
  )
}

import { AlertColor } from '@mui/material'
import { createContext } from 'react'

type SnackbarAlertContextProps = {
  showSnackbarAlert: (props: {
    content?: JSX.Element | string
    autoHideDuration?: number
    severity?: AlertColor
  }) => void
}

const SnackbarAlertContext = createContext<SnackbarAlertContextProps>({
  showSnackbarAlert: () => undefined
})

export default SnackbarAlertContext

import { createContext } from 'react'
import { User as FirebaseUser } from 'firebase/auth'
import { FirebaseSignInProvider, User } from '@/context/type'

type AuthContextProps = {
  getUserToken: () => Promise<string | null>
  user: User | null
  firebaseUser?: FirebaseUser | null
  loading: boolean
  signIn: (providerId: FirebaseSignInProvider) => Promise<void>
  signOut: () => Promise<void>
}

const AuthContext = createContext<AuthContextProps>({
  getUserToken: () => Promise.resolve(''),
  user: null,
  firebaseUser: null,
  loading: true,
  signIn: async () => undefined,
  signOut: async () => undefined
})

export default AuthContext

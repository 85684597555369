import { Typography } from '@mui/material'
import TextField from '@mui/material/TextField'
import { useState, useEffect, forwardRef, ForwardedRef } from 'react'

export type Props = {
  onChange?: (value: string, error?: Error) => void
  currency: string
  showingError: boolean
  value?: string
}

export default forwardRef(
  (
    {
      onChange: changeHandler,
      currency,
      showingError,
      value: valueProp
    }: Props,
    ref: ForwardedRef<HTMLInputElement> | undefined
  ): JSX.Element => {
    const isValid = (v: string) => v && parseInt(v) >= 0

    const errorMessage = '格式錯誤'
    const defaultError = new Error(errorMessage)

    const [error, setError] = useState<Error | undefined>()
    const [touched, setTouched] = useState(false)

    const [value, setValue] = useState<string>('')

    useEffect(() => {
      if (valueProp === undefined) return

      setValue(valueProp)
    }, [valueProp])

    useEffect(() => {
      const e = isValid(value) ? undefined : defaultError
      setError(e)
      changeHandler?.(value, e)
    }, [value])

    return (
      <>
        {(touched || showingError) && error && (
          <Typography
            variant="caption"
            color="error"
            sx={{ alignSelf: 'flex-start' }}>
            *{errorMessage}
          </Typography>
        )}
        <TextField
          id="total-price"
          inputRef={ref}
          label={`總價 (${currency})`}
          type="number"
          value={value ?? ''}
          error={(touched || showingError) && !!error}
          onChange={v => {
            setValue(v.target.value)
          }}
          onBlur={() => {
            setTouched(true)
          }}
        />
      </>
    )
  }
)
